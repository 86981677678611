import { defineMessages } from 'react-intl';

const defaultLocale = 'ru';

const defaultMessages = defineMessages({
  jsCurrenciesUah: {
    id: 'js.currencies.UAH',
    defaultMessage: 'грн',
  },
  jsCurrenciesRub: {
    id: 'js.currencies.RUB',
    defaultMessage: 'руб',
  },
  jsCurrenciesUsd: {
    id: 'js.currencies.USD',
    defaultMessage: 'usd',
  },
  jsCurrenciesEur: {
    id: 'js.currencies.EUR',
    defaultMessage: 'eur',
  },
  jsPluralsDaysOne: {
    id: 'js.plurals.days.one',
    defaultMessage: 'день',
  },
  jsPluralsDaysFew: {
    id: 'js.plurals.days.few',
    defaultMessage: 'дня',
  },
  jsPluralsDaysMany: {
    id: 'js.plurals.days.many',
    defaultMessage: 'дней',
  },
  jsPluralsDaysOther: {
    id: 'js.plurals.days.other',
    defaultMessage: 'дней',
  },
  jsPluralsRatingsOne: {
    id: 'js.plurals.ratings.one',
    defaultMessage: 'оценка',
  },
  jsPluralsRatingsFew: {
    id: 'js.plurals.ratings.few',
    defaultMessage: 'оценки',
  },
  jsPluralsRatingsMany: {
    id: 'js.plurals.ratings.many',
    defaultMessage: 'оценок',
  },
  jsPluralsRatingsOther: {
    id: 'js.plurals.ratings.other',
    defaultMessage: 'оценок',
  },
  jsPluralsApartmentsOne: {
    id: 'js.plurals.apartments.one',
    defaultMessage: 'квартира',
  },
  jsPluralsApartmentsFew: {
    id: 'js.plurals.apartments.few',
    defaultMessage: 'квартиры',
  },
  jsPluralsApartmentsMany: {
    id: 'js.plurals.apartments.many',
    defaultMessage: 'квартир',
  },
  jsPluralsApartmentsOther: {
    id: 'js.plurals.apartments.other',
    defaultMessage: 'квартир',
  },
  jsPluralsApartmentsSortedOne: {
    id: 'js.plurals.apartments_sorted.one',
    defaultMessage: 'квартира отсортированы',
  },
  jsPluralsApartmentsSortedFew: {
    id: 'js.plurals.apartments_sorted.few',
    defaultMessage: 'квартиры отсортированы',
  },
  jsPluralsApartmentsSortedMany: {
    id: 'js.plurals.apartments_sorted.many',
    defaultMessage: 'квартир отсортированы',
  },
  jsPluralsApartmentsSortedOther: {
    id: 'js.plurals.apartments_sorted.other',
    defaultMessage: 'квартир отсортированы',
  },
  jsPluralsAdsOne: {
    id: 'js.plurals.ads.one',
    defaultMessage: 'объявление',
  },
  jsPluralsAdsFew: {
    id: 'js.plurals.ads.few',
    defaultMessage: 'объявления',
  },
  jsPluralsAdsMany: {
    id: 'js.plurals.ads.many',
    defaultMessage: 'объявлений',
  },
  jsPluralsAdsOther: {
    id: 'js.plurals.ads.other',
    defaultMessage: 'объявлений',
  },
  jsAdCardStatusViewed: {
    id: 'js.ad_card.status.viewed',
    defaultMessage: 'Просмотрено',
  },
  jsAdCardMeterShort: {
    id: 'js.ad_card.meter_short',
    defaultMessage: 'м',
  },
  jsAdCardMonthShort: {
    id: 'js.ad_card.month_short',
    defaultMessage: 'мес',
  },
  jsAdCardSpaceForRentOneRoomApartments: {
    id: 'js.ad_card.space_for_rent.one_room_apartments',
    defaultMessage: '1-комн.',
  },
  jsAdCardSpaceForRentTwoRoomApartments: {
    id: 'js.ad_card.space_for_rent.two_room_apartments',
    defaultMessage: '2-комн.',
  },
  jsAdCardSpaceForRentThreeRoomApartments: {
    id: 'js.ad_card.space_for_rent.three_room_apartments',
    defaultMessage: '3-комн.',
  },
  jsAdCardSpaceForRentMultiRoomApartments: {
    id: 'js.ad_card.space_for_rent.multi_room_apartments',
    defaultMessage: '4+',
  },
  jsAdCardSpaceForRentRoom: {
    id: 'js.ad_card.space_for_rent.room',
    defaultMessage: 'К',
  },
  jsAdCardSpaceForRentOnMapOneRoomApartments: {
    id: 'js.ad_card.space_for_rent_on_map.one_room_apartments',
    defaultMessage: '1-комн.',
  },
  jsAdCardSpaceForRentOnMapTwoRoomApartments: {
    id: 'js.ad_card.space_for_rent_on_map.two_room_apartments',
    defaultMessage: '2-комн.',
  },
  jsAdCardSpaceForRentOnMapThreeRoomApartments: {
    id: 'js.ad_card.space_for_rent_on_map.three_room_apartments',
    defaultMessage: '3-комн.',
  },
  jsAdCardSpaceForRentOnMapMultiRoomApartments: {
    id: 'js.ad_card.space_for_rent_on_map.multi_room_apartments',
    defaultMessage: '4+',
  },
  jsAdCardSpaceForRentOnMapRoom: {
    id: 'js.ad_card.space_for_rent_on_map.room',
    defaultMessage: 'комната',
  },
  jsAdNewsletterModalNewOffersByEmail: {
    id: 'js.ad_newsletter_modal.new_offers_by_email',
    defaultMessage: 'Получайте новые предложения по\u0026nbsp;почте',
  },
  jsAdNewsletterModalNoteForNewsletter: {
    id: 'js.ad_newsletter_modal.note_for_newsletter',
    defaultMessage:
      'При наличии обновлений по вашему запросу, мы будем один раз в день отправлять новинки вам на почту в течение месяца. Отписаться можно в личном кабинете в разделе',
  },
  jsAdNewsletterModalNoteForNewsletterLink: {
    id: 'js.ad_newsletter_modal.note_for_newsletter_link',
    defaultMessage: 'уведомления',
  },
  jsAdNewsletterModalSelectMoreFilters: {
    id: 'js.ad_newsletter_modal.select_more_filters',
    defaultMessage:
      'Выберите больше фильтров, по вашим параметрам слишком много объявлений',
  },
  jsAdNewsletterModalMoreSubwaysButtonShowCountingOne: {
    id: 'js.ad_newsletter_modal.more_subways_button.show.counting.one',
    defaultMessage: 'и ещё {count} станцию',
  },
  jsAdNewsletterModalMoreSubwaysButtonShowCountingFew: {
    id: 'js.ad_newsletter_modal.more_subways_button.show.counting.few',
    defaultMessage: 'и ещё {count} станции',
  },
  jsAdNewsletterModalMoreSubwaysButtonShowCountingMany: {
    id: 'js.ad_newsletter_modal.more_subways_button.show.counting.many',
    defaultMessage: 'и ещё {count} станций',
  },
  jsAdNewsletterModalMoreSubwaysButtonHideCountingOne: {
    id: 'js.ad_newsletter_modal.more_subways_button.hide.counting.one',
    defaultMessage: 'свернуть станцию',
  },
  jsAdNewsletterModalMoreSubwaysButtonHideCountingFew: {
    id: 'js.ad_newsletter_modal.more_subways_button.hide.counting.few',
    defaultMessage: 'свернуть станции',
  },
  jsAdNewsletterModalMoreSubwaysButtonHideCountingMany: {
    id: 'js.ad_newsletter_modal.more_subways_button.hide.counting.many',
    defaultMessage: 'свернуть станции',
  },
  jsAdNewsletterModalYouChoosed: {
    id: 'js.ad_newsletter_modal.you_choosed',
    defaultMessage: 'Вы выбрали',
  },
  jsAdNewsletterModalEmailPlaceholder: {
    id: 'js.ad_newsletter_modal.email_placeholder',
    defaultMessage: 'Email',
  },
  jsAdNewsletterModalSubscribeButton: {
    id: 'js.ad_newsletter_modal.subscribe_button',
    defaultMessage: 'Подписаться',
  },
  jsAdNewsletterModalSuccessTitle: {
    id: 'js.ad_newsletter_modal.success_title',
    defaultMessage: 'Подписка оформлена!',
  },
  jsAdNewsletterModalSuccessText: {
    id: 'js.ad_newsletter_modal.success_text',
    defaultMessage:
      'Теперь новые объявления по заданным критериям будут приходить вам на почту.',
  },
  jsAdNewsletterModalSuccessTextSubscriptionStatus: {
    id: 'js.ad_newsletter_modal.success_text_subscription_status',
    defaultMessage:
      'Удалить или проверить статус подписки можно в разделе {section} в личном кабинете.',
  },
  jsAdNewsletterModalSuccessTextSubscriptionStatusSection: {
    id: 'js.ad_newsletter_modal.success_text_subscription_status_section',
    defaultMessage: 'уведомления',
  },
  jsAfterCreateModalReady: {
    id: 'js.after_create_modal.ready',
    defaultMessage: 'Готово!',
  },
  jsAfterCreateModalSentForModeration: {
    id: 'js.after_create_modal.sent_for_moderation',
    defaultMessage:
      'Объявление отправлено на\u0026nbsp;модерацию, скоро вам позвонит наш модератор.',
  },
  jsAfterCreateModalTimeContact: {
    id: 'js.after_create_modal.time_contact',
    defaultMessage:
      'Если вы\u0026nbsp;разместили объявление с\u0026nbsp;10\u0026nbsp;до\u0026nbsp;19\u0026nbsp;часов в\u0026nbsp;рабочие дни, мы\u0026nbsp;позвоним в\u0026nbsp;течение 1-2\u0026nbsp;часов.',
  },
  jsAfterCreateModalAdChanging: {
    id: 'js.after_create_modal.ad_changing',
    defaultMessage:
      'Если вы\u0026nbsp;вносили исправления в\u0026nbsp;объявление и\u0026nbsp;они не\u0026nbsp;требуют дополнительной проверки, мы\u0026nbsp;разместим его на\u0026nbsp;сайте без звонка модератора.',
  },
  jsAfterCreateModalPublicationNotice: {
    id: 'js.after_create_modal.publication_notice',
    defaultMessage:
      'После проверки вашего объявления мы\u0026nbsp;сразу вышлем емейл с\u0026nbsp;результатами: опубликовано или отклонено.',
  },
  jsAfterCreateModalContactUs: {
    id: 'js.after_create_modal.contact_us',
    defaultMessage:
      'Вам не\u0026nbsp;позвонили в\u0026nbsp;течение 24\u0026nbsp;часов после публикации? Напишите нам на\u0026nbsp;{email}',
  },
  jsAppBannerTitle: {
    id: 'js.app_banner.title',
    defaultMessage: 'А\u0026nbsp;в\u0026nbsp;приложении ещё удобнее',
  },
  jsAppBannerButton: {
    id: 'js.app_banner.button',
    defaultMessage: 'Скачать',
  },
  jsAuthModalLoginTitle: {
    id: 'js.auth_modal.login_title',
    defaultMessage: 'Вход',
  },
  jsAuthModalLoginTitleText: {
    id: 'js.auth_modal.login_title_text',
    defaultMessage: 'Вы здесь впервые? Сначала',
  },
  jsAuthModalLoginTitleLink: {
    id: 'js.auth_modal.login_title_link',
    defaultMessage: 'зарегистрируйтесь',
  },
  jsAuthModalLoginFastSocialAuth: {
    id: 'js.auth_modal.login_fast_social_auth',
    defaultMessage: 'Быстрый вход через социальные сети',
  },
  jsAuthModalRegisterTitle: {
    id: 'js.auth_modal.register_title',
    defaultMessage: 'Регистрация',
  },
  jsAuthModalRegisterTitleText: {
    id: 'js.auth_modal.register_title_text',
    defaultMessage: 'Вы уже тут были?',
  },
  jsAuthModalRegisterTitleLink: {
    id: 'js.auth_modal.register_title_link',
    defaultMessage: 'Тогда войдите',
  },
  jsAuthModalRegisterFastSocialAuth: {
    id: 'js.auth_modal.register_fast_social_auth',
    defaultMessage: 'Быстрая регистрация через социальные сети',
  },
  jsAuthModalRegisterSuccessTitle: {
    id: 'js.auth_modal.register_success_title',
    defaultMessage: 'Спасибо за\u0026nbsp;регистрацию',
  },
  jsAuthModalRegisterSuccessText: {
    id: 'js.auth_modal.register_success_text',
    defaultMessage:
      'Теперь вы\u0026nbsp;можете размещать объявления и\u0026nbsp;покупать доступ к\u0026nbsp;контактам',
  },
  jsAuthModalRecoveryTitle: {
    id: 'js.auth_modal.recovery_title',
    defaultMessage: 'Восстановление пароля',
  },
  jsAuthModalRecoveryTitleLink: {
    id: 'js.auth_modal.recovery_title_link',
    defaultMessage: 'Вернуться',
  },
  jsAuthModalRecoverySuccessTitle: {
    id: 'js.auth_modal.recovery_success_title',
    defaultMessage: 'Инструкции отправлены',
  },
  jsAuthModalRecoverySuccessText: {
    id: 'js.auth_modal.recovery_success_text',
    defaultMessage:
      'Мы\u0026nbsp;отправили новый пароль на\u0026nbsp;вашу электронную почту.\u003cbr /\u003e Если вы\u0026nbsp;не\u0026nbsp;получили пароль, попробуйте отправить',
  },
  jsAuthModalRecoverySuccessTextLink: {
    id: 'js.auth_modal.recovery_success_text_link',
    defaultMessage: 'еще раз',
  },
  jsAuthModalResetTitle: {
    id: 'js.auth_modal.reset_title',
    defaultMessage: 'Восстановление пароля',
  },
  jsAuthModalResetTitleLink: {
    id: 'js.auth_modal.reset_title_link',
    defaultMessage: 'Вход',
  },
  jsAuthModalResetSuccessTitle: {
    id: 'js.auth_modal.reset_success_title',
    defaultMessage: 'Поздравляем!',
  },
  jsAuthModalResetSuccessText: {
    id: 'js.auth_modal.reset_success_text',
    defaultMessage:
      'Ваш пароль был успешно изменён.\u003cbr /\u003e Сейчас вы будете автоматически авторизированы.',
  },
  jsAuthModalEmailPlaceholder: {
    id: 'js.auth_modal.email_placeholder',
    defaultMessage: 'Электронная почта',
  },
  jsAuthModalPhonePlaceholder: {
    id: 'js.auth_modal.phone_placeholder',
    defaultMessage: 'Номер телефона',
  },
  jsAuthModalPasswordPlaceholder: {
    id: 'js.auth_modal.password_placeholder',
    defaultMessage: 'Пароль',
  },
  jsAuthModalNewPasswordPlaceholder: {
    id: 'js.auth_modal.new_password_placeholder',
    defaultMessage: 'Новый пароль',
  },
  jsAuthModalConfirmPasswordPlaceholder: {
    id: 'js.auth_modal.confirm_password_placeholder',
    defaultMessage: 'Подтверждение пароля',
  },
  jsAuthModalSMSCodePlaceholder: {
    id: 'js.auth_modal.sms_code_placeholder',
    defaultMessage: 'SMS пароль',
  },
  jsAuthModalByEmailLink: {
    id: 'js.auth_modal.by_email_link',
    defaultMessage: 'По емейлу',
  },
  jsAuthModalByPhoneLink: {
    id: 'js.auth_modal.by_phone_link',
    defaultMessage: 'По телефону',
  },
  jsAuthModalValidationFieldRequired: {
    id: 'js.auth_modal.validation.field_required',
    defaultMessage: 'Это поле обязательное',
  },
  jsAuthModalValidationFieldWrongFormat: {
    id: 'js.auth_modal.validation.field_wrong_format',
    defaultMessage: 'Поле имеет неправильный формат',
  },
  jsAuthModalValidationPasswordMinlength: {
    id: 'js.auth_modal.validation.password_minlength',
    defaultMessage: 'Пароль должен быть не менее 1 символа',
  },
  jsAuthModalValidationPasswordEqualTo: {
    id: 'js.auth_modal.validation.password_equal_to',
    defaultMessage: 'Пароли должны совпадать',
  },
  jsAuthModalAcceptTermsOfUse: {
    id: 'js.auth_modal.accept_terms_of_use',
    defaultMessage: 'Регистрируясь, вы принимаете условия',
  },
  jsAuthModalAcceptTermsOfUseLink: {
    id: 'js.auth_modal.accept_terms_of_use_link',
    defaultMessage: 'пользовательского соглашения',
  },
  jsAuthModalAcceptTermsOfUseAndAgree: {
    id: 'js.auth_modal.accept_terms_of_use_and_agree',
    defaultMessage: 'и соглашаетесь на',
  },
  jsAuthModalAcceptTermsOfUsePersonalDataLink: {
    id: 'js.auth_modal.accept_terms_of_use_personal_data_link',
    defaultMessage: 'обработку ваших персональных данных',
  },
  jsAuthModalDoNotRememberPassword: {
    id: 'js.auth_modal.do_not_remember_password',
    defaultMessage: 'Не помните пароль? Не беда – давайте',
  },
  jsAuthModalDoNotRememberPasswordLink: {
    id: 'js.auth_modal.do_not_remember_password_link',
    defaultMessage: 'восстановим',
  },
  jsAuthModalSMSCodeSuccessNote: {
    id: 'js.auth_modal.sms_code_success_note',
    defaultMessage: 'Мы\u0026nbsp;отправили на\u0026nbsp;ваш номер SMS-код',
  },
  jsAuthModalSMSCodeTimerNote: {
    id: 'js.auth_modal.sms_code_timer_note',
    defaultMessage: 'Запросить SMS-код повторно можно будет через {time}',
  },
  jsAuthModalSMSCodeTimerNoteSecondsOne: {
    id: 'js.auth_modal.sms_code_timer_note_seconds.one',
    defaultMessage: 'секунду',
  },
  jsAuthModalSMSCodeTimerNoteSecondsFew: {
    id: 'js.auth_modal.sms_code_timer_note_seconds.few',
    defaultMessage: 'секунды',
  },
  jsAuthModalSMSCodeTimerNoteSecondsMany: {
    id: 'js.auth_modal.sms_code_timer_note_seconds.many',
    defaultMessage: 'секунд',
  },
  jsAuthModalSMSCodeTimerNoteSecondsOther: {
    id: 'js.auth_modal.sms_code_timer_note_seconds.other',
    defaultMessage: 'секунд',
  },
  jsAuthModalOr: {
    id: 'js.auth_modal.or',
    defaultMessage: 'или',
  },
  jsAuthModalOrPhoneLink: {
    id: 'js.auth_modal.or_phone_link',
    defaultMessage: 'или телефон',
  },
  jsAuthModalOrEmailLink: {
    id: 'js.auth_modal.or_email_link',
    defaultMessage: 'или почта',
  },
  jsAuthModalSignInButton: {
    id: 'js.auth_modal.sign_in_button',
    defaultMessage: 'Войти',
  },
  jsAuthModalSignUpButton: {
    id: 'js.auth_modal.sign_up_button',
    defaultMessage: 'Зарегистрироваться',
  },
  jsAuthModalSendPasswordButton: {
    id: 'js.auth_modal.send_password_button',
    defaultMessage: 'Отправить пароль',
  },
  jsAuthModalChangePasswordButton: {
    id: 'js.auth_modal.change_password_button',
    defaultMessage: 'Изменить пароль',
  },
  jsAuthModalGetSMSCodeButton: {
    id: 'js.auth_modal.get_sms_code_button',
    defaultMessage: 'Получить SMS-код',
  },
  jsCatalogBreadcrumbsHome: {
    id: 'js.catalog.breadcrumbs.home',
    defaultMessage: 'Главная',
  },
  jsCatalogBreadcrumbsSearch: {
    id: 'js.catalog.breadcrumbs.search',
    defaultMessage: 'Поиск',
  },
  jsCatalogSortingAds: {
    id: 'js.catalog.sorting.ads',
    defaultMessage: 'объявлений',
  },
  jsCatalogSortingApartments: {
    id: 'js.catalog.sorting.apartments',
    defaultMessage: 'квартиры отсортированы',
  },
  jsCatalogSortingApartmentsShort: {
    id: 'js.catalog.sorting.apartments_short',
    defaultMessage: 'квартир',
  },
  jsCatalogSortingByDate: {
    id: 'js.catalog.sorting.by_date',
    defaultMessage: 'по дате добавления',
  },
  jsCatalogSortingByPrice: {
    id: 'js.catalog.sorting.by_price',
    defaultMessage: 'по цене',
  },
  jsCatalogSortingByArea: {
    id: 'js.catalog.sorting.by_area',
    defaultMessage: 'по площади',
  },
  jsCatalogViewList: {
    id: 'js.catalog.view.list',
    defaultMessage: 'Списком',
  },
  jsCatalogViewMap: {
    id: 'js.catalog.view.map',
    defaultMessage: 'На карте',
  },
  jsCatalogClosedAccessCardTitle: {
    id: 'js.catalog.closed_access_card.title',
    defaultMessage: 'Не пропустите свой вариант',
  },
  jsCatalogClosedAccessCardText: {
    id: 'js.catalog.closed_access_card.text',
    defaultMessage:
      'Личный помощник видит объявления еще до того, как они попадут на сайт и сразу предлагает их вам',
  },
  jsCatalogClosedAccessCardRequest: {
    id: 'js.catalog.closed_access_card.request',
    defaultMessage: 'Подробнее',
  },
  jsCatalogSubscriptionCardTitle: {
    id: 'js.catalog.subscription_card.title',
    defaultMessage: 'Рассылка с новыми объявлениями',
  },
  jsCatalogSubscriptionCardText: {
    id: 'js.catalog.subscription_card.text',
    defaultMessage:
      'Настройте фильтр и\u0026nbsp;получайте уведомления о\u0026nbsp;подходящих вам квартирах ',
  },
  jsCatalogSubscriptionCardRequest: {
    id: 'js.catalog.subscription_card.request',
    defaultMessage: 'Подписаться',
  },
  jsCatalogWhatsappHelpCardTitle: {
    id: 'js.catalog.whatsapp_help_card.title',
    defaultMessage: 'Консультация в WhatsApp',
  },
  jsCatalogWhatsappHelpCardText: {
    id: 'js.catalog.whatsapp_help_card.text',
    defaultMessage:
      'Расскажем о сервисе и\u0026nbsp;поможем подобрать подходящий именно вам тариф',
  },
  jsCatalogWhatsappHelpCardRequest: {
    id: 'js.catalog.whatsapp_help_card.request',
    defaultMessage: 'Перейти в WhatsApp',
  },
  jsCatalogSearchAssistantCardTitle: {
    id: 'js.catalog.search_assistant_card.title',
    defaultMessage: 'Надоело искать?',
  },
  jsCatalogSearchAssistantCardText: {
    id: 'js.catalog.search_assistant_card.text',
    defaultMessage:
      'Укажите требования к\u0026nbsp;квартире и поручите поиск личному\u0026nbsp;помощнику',
  },
  jsCatalogSearchAssistantCardRequest: {
    id: 'js.catalog.search_assistant_card.request',
    defaultMessage: 'Оставить заявку',
  },
  jsCatalogFilterTitle: {
    id: 'js.catalog.filter.title',
    defaultMessage: 'Фильтр',
  },
  jsCatalogFilterReset: {
    id: 'js.catalog.filter.reset',
    defaultMessage: 'Сбросить',
  },
  jsCatalogFilterApply: {
    id: 'js.catalog.filter.apply',
    defaultMessage: 'Применить',
  },
  jsCatalogFilterRent: {
    id: 'js.catalog.filter.rent',
    defaultMessage: 'Аренда длительно',
  },
  jsCatalogFilterDailyRent: {
    id: 'js.catalog.filter.daily_rent',
    defaultMessage: 'Аренда посуточно',
  },
  jsCatalogFilterSale: {
    id: 'js.catalog.filter.sale',
    defaultMessage: 'Продажа',
  },
  jsCatalogFilterWhat: {
    id: 'js.catalog.filter.what',
    defaultMessage: 'Что',
  },
  jsCatalogFilterApartments: {
    id: 'js.catalog.filter.apartments',
    defaultMessage: 'Квартиры',
  },
  jsCatalogFilterRooms: {
    id: 'js.catalog.filter.rooms',
    defaultMessage: 'Комнаты',
  },
  jsCatalogFilterSex: {
    id: 'js.catalog.filter.sex',
    defaultMessage: 'Подходит для',
  },
  jsCatalogFilterSexAny: {
    id: 'js.catalog.filter.sex_any',
    defaultMessage: 'Всех',
  },
  jsCatalogFilterSexFemale: {
    id: 'js.catalog.filter.sex_female',
    defaultMessage: 'Девушек',
  },
  jsCatalogFilterSexMale: {
    id: 'js.catalog.filter.sex_male',
    defaultMessage: 'Парней',
  },
  jsCatalogFilterWhere: {
    id: 'js.catalog.filter.where',
    defaultMessage: 'Где',
  },
  jsCatalogFilterSelectInTheScheme: {
    id: 'js.catalog.filter.select_in_the_scheme',
    defaultMessage: 'Выбрать на схеме',
  },
  jsCatalogFilterAreaOnTheMap: {
    id: 'js.catalog.filter.area_on_the_map',
    defaultMessage: 'Область на карте',
  },
  jsCatalogFilterInTheSchemeOrByName: {
    id: 'js.catalog.filter.in_the_scheme_or_by_name',
    defaultMessage: 'На схеме или по названию',
  },
  jsCatalogFilterTypeTheSubwayName: {
    id: 'js.catalog.filter.type_the_subway_name',
    defaultMessage: 'Введите название станции',
  },
  jsCatalogFilterTypeTheDistrictName: {
    id: 'js.catalog.filter.type_the_district_name',
    defaultMessage: 'Введите название района',
  },
  jsCatalogFilterClear: {
    id: 'js.catalog.filter.clear',
    defaultMessage: 'Очистить',
  },
  jsCatalogFilterNumberOfRooms: {
    id: 'js.catalog.filter.number_of_rooms',
    defaultMessage: 'Количество комнат',
  },
  jsCatalogFilterDistanceFromSubway: {
    id: 'js.catalog.filter.distance_from_subway',
    defaultMessage: 'Расстояние от метро',
  },
  jsCatalogFilterRouteTypeNoMatter: {
    id: 'js.catalog.filter.route_type_no_matter',
    defaultMessage: 'Неважно',
  },
  jsCatalogFilterRouteTypeOnFoot: {
    id: 'js.catalog.filter.route_type_on_foot',
    defaultMessage: 'Пешком',
  },
  jsCatalogFilterRouteTypeTransport: {
    id: 'js.catalog.filter.route_type_transport',
    defaultMessage: 'Транспортом',
  },
  jsCatalogFilterMinutesShort: {
    id: 'js.catalog.filter.minutes_short',
    defaultMessage: 'мин',
  },
  jsCatalogFilterPrice: {
    id: 'js.catalog.filter.price',
    defaultMessage: 'Цена',
  },
  jsCatalogFilterFrom: {
    id: 'js.catalog.filter.from',
    defaultMessage: 'от',
  },
  jsCatalogFilterTo: {
    id: 'js.catalog.filter.to',
    defaultMessage: 'до',
  },
  jsCatalogFilterSource: {
    id: 'js.catalog.filter.source',
    defaultMessage: 'Источник',
  },
  jsCatalogFilterOnlyVerified: {
    id: 'js.catalog.filter.only_verified',
    defaultMessage: 'Только проверенные',
  },
  jsCatalogFilterOnlyVerifiedTooltip: {
    id: 'js.catalog.filter.only_verified_tooltip',
    defaultMessage:
      'Объявления из проверенных источников, одобренные модераторами',
  },
  jsCatalogFilterAdditionally: {
    id: 'js.catalog.filter.additionally',
    defaultMessage: 'Дополнительно',
  },
  jsCatalogFilterSpace: {
    id: 'js.catalog.filter.space',
    defaultMessage: 'Площадь в м\u003csup\u003e2\u003c/sup\u003e',
  },
  jsCatalogFilterFloor: {
    id: 'js.catalog.filter.floor',
    defaultMessage: 'Этаж',
  },
  jsCatalogFilterFacilities: {
    id: 'js.catalog.filter.facilities',
    defaultMessage: 'Удобства',
  },
  jsCatalogFilterFeatures: {
    id: 'js.catalog.filter.features',
    defaultMessage: 'Особенности',
  },
  jsCatalogLoadMore: {
    id: 'js.catalog.load_more',
    defaultMessage: 'Загрузить еще',
  },
  jsCatalogMapPlacemarkHintCurrentOffer: {
    id: 'js.catalog.map.placemark_hint.current_offer',
    defaultMessage: 'актуальное предложение',
  },
  jsCatalogMapPlacemarkHintViewed: {
    id: 'js.catalog.map.placemark_hint.viewed',
    defaultMessage: 'просмотрено',
  },
  jsCatalogMapDrawerSelectAreaButton: {
    id: 'js.catalog.map.drawer.select_area_button',
    defaultMessage: 'Обвести',
  },
  jsCatalogMapDrawerExitButton: {
    id: 'js.catalog.map.drawer.exit_button',
    defaultMessage: 'Выйти',
  },
  jsCatalogMapDrawerClearButton: {
    id: 'js.catalog.map.drawer.clear_button',
    defaultMessage: 'Очистить',
  },
  jsContactsButtonShowPhoneNumber: {
    id: 'js.contacts_button.show_phone_number',
    defaultMessage: 'Телефон',
  },
  jsContactsButtonOwnerPhoneNumber: {
    id: 'js.contacts_button.owner_phone_number',
    defaultMessage: 'Телефон',
  },
  jsContactsButtonLeaveRequest: {
    id: 'js.contacts_button.leave_request',
    defaultMessage: 'Оставить заявку',
  },
  jsContactsModalEvaluateCall: {
    id: 'js.contacts_modal.evaluate_call',
    defaultMessage:
      'Оцените \u003cspan class="hidden-xs"\u003eзвонок\u003c/span\u003e',
  },
  jsContactsModalUnverifiedNotice: {
    id: 'js.contacts_modal.unverified_notice',
    defaultMessage:
      'Осторожно, это объявление еще не\u0026nbsp;проверено, Локалс не\u0026nbsp;рекомендует самостоятельно связываться с\u0026nbsp;собственником, лучше поручите это \u003ca href="{url}"\u003eличному помощнику\u003c/a\u003e',
  },
  jsContactsModalOtherOwnerContacts: {
    id: 'js.contacts_modal.other_owner_contacts',
    defaultMessage: 'Другие контакты собственника:{contacts}',
  },
  jsContactsModalLabelNotForAgents: {
    id: 'js.contacts_modal.label_not_for_agents',
    defaultMessage: 'Агентам не звонить',
  },
  jsContactsModalNoteAboutPrepayment: {
    id: 'js.contacts_modal.note_about_prepayment',
    defaultMessage:
      'Друзья, обратите внимание. Никогда, ни\u0026nbsp;при каких обстоятельствах \u003cspan class="marked"\u003eне\u0026nbsp;передавайте аванс за\u0026nbsp;квартиру до\u0026nbsp;тех пор, пока вы\u0026nbsp;не\u0026nbsp;посетите её\u0026nbsp;лично и\u0026nbsp;не\u0026nbsp;увидите документы,\u003c/span\u003e подтверждающие право собственности.',
  },
  jsContactsModalAssistantText: {
    id: 'js.contacts_modal.assistant_text',
    defaultMessage:
      'Эта квартира сдается нашим проверенным партнером - приятным квалифицированным человеком, который ответит на все ваши вопросы и во всем вам поможет. Разумеется, никакой комиссии.',
  },
  jsContactsModalCallLikeButton: {
    id: 'js.contacts_modal.call_like_button',
    defaultMessage: 'Все хорошо',
  },
  jsContactsModalCallDislikeButton: {
    id: 'js.contacts_modal.call_dislike_button',
    defaultMessage: 'Пожаловаться',
  },
  jsContactsModalCallDislikeDropdownReasonNotRelevant: {
    id: 'js.contacts_modal.call_dislike_dropdown.reason_not_relevant',
    defaultMessage: 'Объявление не актуально',
  },
  jsContactsModalCallDislikeDropdownReasonDoNotAnswer: {
    id: 'js.contacts_modal.call_dislike_dropdown.reason_do_not_answer',
    defaultMessage: 'Не берут трубку',
  },
  jsContactsModalCallDislikeDropdownReasonFalsePhotos: {
    id: 'js.contacts_modal.call_dislike_dropdown.reason_false_photos',
    defaultMessage: 'Фотографии ложные',
  },
  jsContactsModalCallDislikeDropdownReasonComission: {
    id: 'js.contacts_modal.call_dislike_dropdown.reason_comission',
    defaultMessage: 'Есть комиссия',
  },
  jsContactsModalCallDislikeDropdownReasonAnother: {
    id: 'js.contacts_modal.call_dislike_dropdown.reason_another',
    defaultMessage: 'Другая причина (укажите)',
  },
  jsContactsModalCallDislikeFormReasonPlaceholder: {
    id: 'js.contacts_modal.call_dislike_form.reason_placeholder',
    defaultMessage: 'Укажите причину',
  },
  jsContactsModalCallDislikeFormSubmitButton: {
    id: 'js.contacts_modal.call_dislike_form.submit_button',
    defaultMessage: 'Отправить',
  },
  jsControlFavoriteRemoveFromFavorites: {
    id: 'js.control_favorite.remove_from_favorites',
    defaultMessage: 'Убрать из избранного',
  },
  jsControlFavoriteAddToFavorites: {
    id: 'js.control_favorite.add_to_favorites',
    defaultMessage: 'Добавить в избранное',
  },
  jsControlFavoriteRemovingFromFavorites: {
    id: 'js.control_favorite.removing_from_favorites',
    defaultMessage: 'Убираем из избранного...',
  },
  jsControlFavoriteAddingToFavorites: {
    id: 'js.control_favorite.adding_to_favorites',
    defaultMessage: 'Добавляем в избранное...',
  },
  jsControlFavoriteAuthDropdownYouNeedTo: {
    id: 'js.control_favorite.auth_dropdown.you_need_to',
    defaultMessage: 'Вам необходимо',
  },
  jsControlFavoriteAuthDropdownSignIn: {
    id: 'js.control_favorite.auth_dropdown.sign_in',
    defaultMessage: 'войти',
  },
  jsControlFavoriteAuthDropdownOr: {
    id: 'js.control_favorite.auth_dropdown.or',
    defaultMessage: 'или',
  },
  jsControlFavoriteAuthDropdownSignUp: {
    id: 'js.control_favorite.auth_dropdown.sign_up',
    defaultMessage: 'зарегистрироваться',
  },
  jsControlPromoTooltipAdPromoted: {
    id: 'js.control_promo.tooltip_ad_promoted',
    defaultMessage: 'Это объявление продвигается',
  },
  jsControlPromoDropdownTitle: {
    id: 'js.control_promo.dropdown_title',
    defaultMessage: 'Это объявление продвигается. Хотите продвинуть своё?',
  },
  jsControlPromoDropdownMoreViews: {
    id: 'js.control_promo.dropdown_more_views',
    defaultMessage: 'В\u0026nbsp;10\u0026nbsp;раз больше просмотров',
  },
  jsControlPromoDropdownAlwaysOnTop: {
    id: 'js.control_promo.dropdown_always_on_top',
    defaultMessage: 'Всегда наверху в\u0026nbsp;поиске',
  },
  jsControlPromoDropdownHighlightedInList: {
    id: 'js.control_promo.dropdown_highlighted_in_list',
    defaultMessage: 'Выделено в\u0026nbsp;списке',
  },
  jsControlPromoDropdownRecommendation: {
    id: 'js.control_promo.dropdown_recommendation',
    defaultMessage: 'Включено в\u0026nbsp;рекомендации Locals',
  },
  jsControlPromoDropdownOrderButton: {
    id: 'js.control_promo.dropdown_order_button',
    defaultMessage: 'Заказать',
  },
  jsFavoriteButtonFavorited: {
    id: 'js.favorite_button.favorited',
    defaultMessage: 'В избранном',
  },
  jsFavoriteButtonToFavorites: {
    id: 'js.favorite_button.to_favorites',
    defaultMessage: 'В избранное',
  },
  jsFavoriteButtonWait: {
    id: 'js.favorite_button.wait',
    defaultMessage: 'Подождите...',
  },
  jsMetroSchemeModalApplyButton: {
    id: 'js.metro_scheme_modal.apply_button',
    defaultMessage: 'Применить',
  },
  jsMetroSchemeModalClearButton: {
    id: 'js.metro_scheme_modal.clear_button',
    defaultMessage: 'Очистить все станции',
  },
  jsPlanSocialButtonMessage: {
    id: 'js.plan_social_button.message',
    defaultMessage:
      'Ищу квартиру в аренду без комиссии на Локалс. Советую! #locals_ru',
  },
  jsMainFiltersTitle: {
    id: 'js.main_filters.title',
    defaultMessage: 'Аренда квартир без\u0026nbsp;комиссии',
  },
  jsMainFiltersIn: {
    id: 'js.main_filters.in',
    defaultMessage: 'в',
  },
  jsMainFiltersAnySubway: {
    id: 'js.main_filters.any_subway',
    defaultMessage: 'Любое метро',
  },
  jsMainFiltersRoom: {
    id: 'js.main_filters.room',
    defaultMessage: 'Комната',
  },
  jsMainFiltersOneRoomApartments: {
    id: 'js.main_filters.one_room_apartments',
    defaultMessage: 'Однокомнатные квартиры',
  },
  jsMainFiltersTwoRoomApartments: {
    id: 'js.main_filters.two_room_apartments',
    defaultMessage: 'Двухкомнатные квартиры',
  },
  jsMainFiltersThreeRoomApartments: {
    id: 'js.main_filters.three_room_apartments',
    defaultMessage: 'Трехкомнатные квартиры',
  },
  jsMainFiltersMultiRoomApartments: {
    id: 'js.main_filters.multi_room_apartments',
    defaultMessage: 'Многокомнатные квартиры',
  },
  jsMainFiltersSubmitButton: {
    id: 'js.main_filters.submit_button',
    defaultMessage: 'Найти объявления',
  },
  jsSettingsModalChangeAvatar: {
    id: 'js.settings_modal.change_avatar',
    defaultMessage: 'Сменить аватарку',
  },
  jsSettingsModalTitlePersonalInformation: {
    id: 'js.settings_modal.title_personal_information',
    defaultMessage: 'Личная информация',
  },
  jsSettingsModalTitleChangePassword: {
    id: 'js.settings_modal.title_change_password',
    defaultMessage: 'Сменить пароль',
  },
  jsSettingsModalTitlePhone: {
    id: 'js.settings_modal.title_phone',
    defaultMessage: 'Телефон',
  },
  jsSettingsModalTitlePhoneBind: {
    id: 'js.settings_modal.title_phone_bind',
    defaultMessage: 'Привязать телефон',
  },
  jsSettingsModalTitleSocialAccounts: {
    id: 'js.settings_modal.title_social_accounts',
    defaultMessage: 'Привязать учетную запись',
  },
  jsSettingsModalNamePlaceholder: {
    id: 'js.settings_modal.name_placeholder',
    defaultMessage: 'Имя',
  },
  jsSettingsModalEmailPlaceholder: {
    id: 'js.settings_modal.email_placeholder',
    defaultMessage: 'Электронная почта',
  },
  jsSettingsModalNewPasswordPlaceholder: {
    id: 'js.settings_modal.new_password_placeholder',
    defaultMessage: 'Новый пароль',
  },
  jsSettingsModalConfirmPasswordPlaceholder: {
    id: 'js.settings_modal.confirm_password_placeholder',
    defaultMessage: 'Подтверждение пароля',
  },
  jsSettingsModalPhonePlaceholder: {
    id: 'js.settings_modal.phone_placeholder',
    defaultMessage: 'Номер телефона',
  },
  jsSettingsModalSMSCodePlaceholder: {
    id: 'js.settings_modal.sms_code_placeholder',
    defaultMessage: 'SMS пароль',
  },
  jsSettingsModalSMSCodeSuccessNote: {
    id: 'js.settings_modal.sms_code_success_note',
    defaultMessage: 'Мы\u0026nbsp;отправили на\u0026nbsp;ваш номер SMS-код',
  },
  jsSettingsModalSMSCodeTimerNote: {
    id: 'js.settings_modal.sms_code_timer_note',
    defaultMessage:
      'Изменить номер телефона или запросить SMS-код повторно можно будет через {time}',
  },
  jsSettingsModalSMSCodeTimerNoteSecondsOne: {
    id: 'js.settings_modal.sms_code_timer_note_seconds.one',
    defaultMessage: 'секунду',
  },
  jsSettingsModalSMSCodeTimerNoteSecondsFew: {
    id: 'js.settings_modal.sms_code_timer_note_seconds.few',
    defaultMessage: 'секунды',
  },
  jsSettingsModalSMSCodeTimerNoteSecondsMany: {
    id: 'js.settings_modal.sms_code_timer_note_seconds.many',
    defaultMessage: 'секунд',
  },
  jsSettingsModalSMSCodeTimerNoteSecondsOther: {
    id: 'js.settings_modal.sms_code_timer_note_seconds.other',
    defaultMessage: 'секунд',
  },
  jsSettingsModalResendSMSCodeLink: {
    id: 'js.settings_modal.resend_sms_code_link',
    defaultMessage: 'Отправить SMS-код повторно',
  },
  jsSettingsModalGetSMSCodeButton: {
    id: 'js.settings_modal.get_sms_code_button',
    defaultMessage: 'Получить SMS-код',
  },
  jsSettingsModalChangeButton: {
    id: 'js.settings_modal.change_button',
    defaultMessage: 'Изменить',
  },
  jsSettingsModalSaveButton: {
    id: 'js.settings_modal.save_button',
    defaultMessage: 'Сохранить',
  },
  jsSettingsModalContactUs: {
    id: 'js.settings_modal.contact_us',
    defaultMessage: 'Вопросы? Пишите на {email}',
  },
  jsSettingsModalValidationPasswordMinlength: {
    id: 'js.settings_modal.validation.password_minlength',
    defaultMessage: 'Пароль должен быть не менее 1 символа',
  },
  jsSettingsModalValidationPasswordEqualTo: {
    id: 'js.settings_modal.validation.password_equal_to',
    defaultMessage: 'Пароли должны совпадать',
  },
  jsSettingsModalValidationPasswordConfirmationRequired: {
    id: 'js.settings_modal.validation.password_confirmation_required',
    defaultMessage: 'Введите пароль повторно',
  },
  jsSettingsModalValidationPasswordConfirmationMinlength: {
    id: 'js.settings_modal.validation.password_confirmation_minlength',
    defaultMessage: 'Пароль должен быть не менее 1 символа',
  },
  jsSettingsModalValidationPhoneWrongFormat: {
    id: 'js.settings_modal.validation.phone_wrong_format',
    defaultMessage: 'Неверный формат телефона',
  },
  jsSettingsModalValidationFieldWrongFormat: {
    id: 'js.settings_modal.validation.field_wrong_format',
    defaultMessage: 'Поле имеет неправильный формат',
  },
  jsSocialButtonVkontakte: {
    id: 'js.social_button.vkontakte',
    defaultMessage: 'Вконтакте',
  },
  jsSocialButtonFacebook: {
    id: 'js.social_button.facebook',
    defaultMessage: 'Facebook',
  },
  jsFlashNotifierCouldNotLoadImage: {
    id: 'js.flash_notifier.could_not_load_image',
    defaultMessage: 'Не удалось загрузить изображение',
  },
  jsFlashNotifierErrorHasOccurredTryAgain: {
    id: 'js.flash_notifier.error_has_occurred_try_again',
    defaultMessage: 'Произошла ошибка! Попробуйте еще раз.',
  },
  jsFlashNotifierErrorHasOccurredRefreshBrowser: {
    id: 'js.flash_notifier.error_has_occurred_refresh_browser',
    defaultMessage: 'Произошла ошибка! Попробуйте обновить страницу.',
  },
  jsFlashNotifierErrorHasOccurredCheckConnection: {
    id: 'js.flash_notifier.error_has_occurred_check_connection',
    defaultMessage: 'Произошла ошибка! Проверьте соединение с интернетом.',
  },
  jsFlashNotifierCouldNotLoadAllAdsTryAgain: {
    id: 'js.flash_notifier.could_not_load_all_ads_try_again',
    defaultMessage: 'Не удалось загрузить все объявления! Попробуйте ещё раз.',
  },
  jsFlashNotifierSessionIsOutdated: {
    id: 'js.flash_notifier.session_is_outdated',
    defaultMessage: 'Сессия устарела! Попробуйте еще раз восстановить пароль.',
  },
  jsFlashNotifierSomethingWentWrong: {
    id: 'js.flash_notifier.something_went_wrong',
    defaultMessage:
      'Что-то пошло не так! Попробуйте чуть позже еще раз. Спасибо',
  },
  jsFlashNotifierThanksForMark: {
    id: 'js.flash_notifier.thanks_for_mark',
    defaultMessage:
      'Спасибо за Вашу оценку! Вы помогаете нам сделать сервис лучше',
  },
  jsFlashNotifierAddedToFavorites: {
    id: 'js.flash_notifier.added_to_favorites',
    defaultMessage:
      'Добавлено в \u003ca href="{link}" title="избранное"\u003eизбранное\u003c/a\u003e',
  },
  jsFlashNotifierThereAreNoPanoramas: {
    id: 'js.flash_notifier.there_are_no_panoramas',
    defaultMessage: 'В заданной точке нет панорам.',
  },
  jsAdFormExtraOptionLivingSize: {
    id: 'js.ad_form.extra_option.living_size',
    defaultMessage: 'Жилая площадь',
  },
  jsAdFormExtraOptionBalconyCount: {
    id: 'js.ad_form.extra_option.balcony_count',
    defaultMessage: 'Балкон',
  },
  jsAdFormExtraOptionBathroom: {
    id: 'js.ad_form.extra_option.bathroom',
    defaultMessage: 'Санузел',
  },
  jsAdFormExtraOptionLoggiaCount: {
    id: 'js.ad_form.extra_option.loggia_count',
    defaultMessage: 'Лоджия',
  },
  jsAdFormExtraOptionBalcony: {
    id: 'js.ad_form.extra_option.balcony',
    defaultMessage: 'Балконы',
  },
  jsAdFormExtraOptionWindowsPosition: {
    id: 'js.ad_form.extra_option.windows_position',
    defaultMessage: 'Балконы и окна',
  },
  jsAdFormExtraOptionEntrance: {
    id: 'js.ad_form.extra_option.entrance',
    defaultMessage: 'Подъезд',
  },
  jsAdFormExtraOptionSeparateBathroomCount: {
    id: 'js.ad_form.extra_option.separate_bathroom_count',
    defaultMessage: 'Раздельный',
  },
  jsAdFormExtraOptionCombineBathroomCount: {
    id: 'js.ad_form.extra_option.combine_bathroom_count',
    defaultMessage: 'Совмещенный',
  },
  jsAdFormExtraOptionRepairType: {
    id: 'js.ad_form.extra_option.repair_type',
    defaultMessage: 'Ремонт',
  },
  jsAdFormExtraOptionRepairTypesNone: {
    id: 'js.ad_form.extra_option.repair_types.none',
    defaultMessage: 'Без ремонта',
  },
  jsAdFormExtraOptionRepairTypesCosmetic: {
    id: 'js.ad_form.extra_option.repair_types.cosmetic',
    defaultMessage: 'Косметический',
  },
  jsAdFormExtraOptionRepairTypesEuro: {
    id: 'js.ad_form.extra_option.repair_types.euro',
    defaultMessage: 'Евро',
  },
  jsAdFormExtraOptionRepairTypesDesign: {
    id: 'js.ad_form.extra_option.repair_types.design',
    defaultMessage: 'Дизайнерский ремонт',
  },
  jsAdFormExtraOptionParkingType: {
    id: 'js.ad_form.extra_option.parking_type',
    defaultMessage: 'Парковка',
  },
  jsAdFormExtraOptionParkingTypesGround: {
    id: 'js.ad_form.extra_option.parking_type.ground',
    defaultMessage: 'Наземная',
  },
  jsAdFormExtraOptionParkingTypesMultiLevel: {
    id: 'js.ad_form.extra_option.parking_type.multi_level',
    defaultMessage: 'Многоуровневая',
  },
  jsAdFormExtraOptionParkingTypesUnderground: {
    id: 'js.ad_form.extra_option.parking_type.underground',
    defaultMessage: 'Подземная',
  },
  jsAdFormExtraOptionParkingTypesRooftop: {
    id: 'js.ad_form.extra_option.parking_type.rooftop',
    defaultMessage: 'На крыше',
  },
  jsAdFormExtraOptionElevator: {
    id: 'js.ad_form.extra_option.elevator',
    defaultMessage: 'Лифты',
  },
  jsAdFormExtraOptionPassengerElevatorCount: {
    id: 'js.ad_form.extra_option.passenger-elevator-count',
    defaultMessage: 'Пассажирский',
  },
  jsAdFormExtraOptionServiceElevatorCount: {
    id: 'js.ad_form.extra_option.service-elevator-count',
    defaultMessage: 'Грузовой',
  },
  jsAdFormExtraOptionKitchenSize: {
    id: 'js.ad_form.extra_option.kitchen_size',
    defaultMessage: 'Кухня',
  },
  jsAdFormExtraOptionAptNumber: {
    id: 'js.ad_form.extra_option.apt_number',
    defaultMessage: 'Номер квартиры',
  },
  jsAdFormExtraOptionBuildYear: {
    id: 'js.ad_form.extra_option.build_year',
    defaultMessage: 'Год постройки',
  },
  jsAdFormExtraOptionCeilingHeight: {
    id: 'js.ad_form.extra_option.ceiling_height',
    defaultMessage: 'Высота потолков',
  },
  jsAdFormExtraOptionBuildingType: {
    id: 'js.ad_form.extra_option.building_type',
    defaultMessage: 'Тип дома',
  },
  jsAdFormExtraOptionBuildingTypesBrick: {
    id: 'js.ad_form.extra_option.building_types.brick',
    defaultMessage: 'Кирпичный',
  },
  jsAdFormExtraOptionBuildingTypesMonolithic: {
    id: 'js.ad_form.extra_option.building_types.monolithic',
    defaultMessage: 'Монолитный',
  },
  jsAdFormExtraOptionBuildingTypesPanel: {
    id: 'js.ad_form.extra_option.building_types.panel',
    defaultMessage: 'Панельный',
  },
  jsAdFormExtraOptionBuildingTypesBlocky: {
    id: 'js.ad_form.extra_option.building_types.blocky',
    defaultMessage: 'Блочный',
  },
  jsAdFormExtraOptionBuildingTypesWood: {
    id: 'js.ad_form.extra_option.building_types.wood',
    defaultMessage: 'Деревянный',
  },
  jsAdFormExtraOptionBuildingTypesStalinist: {
    id: 'js.ad_form.extra_option.building_types.stalinist',
    defaultMessage: 'Сталинский',
  },
  jsAdFormExtraOptionBuildingTypesMonolithicBrick: {
    id: 'js.ad_form.extra_option.building_types.monolithic_brick',
    defaultMessage: 'Монолитно-кирпичный',
  },
  jsAdFormExtraOptionPropetryType: {
    id: 'js.ad_form.extra_option.propetry_type',
    defaultMessage: 'Тип недвижимости',
  },
  jsAdFormExtraOptionCadastralNumber: {
    id: 'js.ad_form.extra_option.cadastral_number',
    defaultMessage: 'Кадастровый номер',
  },
  jsAdFormStep: {
    id: 'js.ad_form.step',
    defaultMessage: 'Шаг',
  },
  jsAdFormOr: {
    id: 'js.ad_form.or',
    defaultMessage: 'или',
  },
  jsAdFormErrorsEmail: {
    id: 'js.ad_form.errors.email',
    defaultMessage: 'Email имеет неправильный формат',
  },
  jsAdFormErrorsFieldRequired: {
    id: 'js.ad_form.errors.field_required',
    defaultMessage: 'Поле обязательно к заполнению',
  },
  jsAdFormErrorsSelectRequired: {
    id: 'js.ad_form.errors.select_required',
    defaultMessage: 'Необходимо что-то выбрать',
  },
  jsAdFormErrorsImageRequired: {
    id: 'js.ad_form.errors.image_required',
    defaultMessage: 'Загрузите хотя бы 1 изображение',
  },
  jsAdFormErrorsMustBeNumeric: {
    id: 'js.ad_form.errors.must_be_numeric',
    defaultMessage:
      'Следующие поля должны быть числовыми: Площадь, Этаж, Этажей в доме',
  },
  jsAdFormErrorsGreaterThan: {
    id: 'js.ad_form.errors.greater_than',
    defaultMessage: 'Введите число больше 0',
  },
  jsAdFormErrorsPhoneWrongFormat: {
    id: 'js.ad_form.errors.phone_wrong_format',
    defaultMessage: 'Неверный формат телефона',
  },
  jsAdFormCityLabel: {
    id: 'js.ad_form.city.label',
    defaultMessage: 'Город',
  },
  jsAdFormAuthLabel: {
    id: 'js.ad_form.auth.label',
    defaultMessage: 'Вход или регистрация',
  },
  jsAdFormAuthFootnotesRegister: {
    id: 'js.ad_form.auth.footnotes_register',
    defaultMessage: 'Регистрировались?',
  },
  jsAdFormAuthFootnotesSign: {
    id: 'js.ad_form.auth.footnotes_sign',
    defaultMessage: 'Войдите',
  },
  jsAdFormKindLabel: {
    id: 'js.ad_form.kind.label',
    defaultMessage: 'Что вы сдаете?',
  },
  jsAdFormKindLabelSale: {
    id: 'js.ad_form.kind.label_sale',
    defaultMessage: 'Что вы продаете?',
  },
  jsAdFormKindRentRoom: {
    id: 'js.ad_form.kind.rent_room',
    defaultMessage: 'Комнату',
  },
  jsAdFormKindRentApartment: {
    id: 'js.ad_form.kind.rent_apartment',
    defaultMessage: 'Квартиру',
  },
  jsAdFormKindType: {
    id: 'js.ad_form.kind.type',
    defaultMessage: 'Выберите тип объявления',
  },
  jsAdFormAddressLabel: {
    id: 'js.ad_form.address.label',
    defaultMessage: 'Введите адрес',
  },
  jsAdFormAddressPlaceholder: {
    id: 'js.ad_form.address.placeholder',
    defaultMessage: 'Например, Профсоюзная, 10',
  },
  jsAdFormAddressFootnote: {
    id: 'js.ad_form.address.footnote',
    defaultMessage: 'Пожалуйста, убедитесь, что адрес отображается на карте',
  },
  jsAdFormSubwaysLabel: {
    id: 'js.ad_form.subways.label',
    defaultMessage: 'Ближайшее метро',
  },
  jsAdFormDistrictLabel: {
    id: 'js.ad_form.district.label',
    defaultMessage: 'Выберите район',
  },
  jsAdFormTitleTitle: {
    id: 'js.ad_form.title.title',
    defaultMessage: 'Заголовок',
  },
  jsAdFormTitlePlaceholder: {
    id: 'js.ad_form.title.placeholder',
    defaultMessage: 'Например: «Трёшка на Патриках».',
  },
  jsAdFormDescriptionLabel: {
    id: 'js.ad_form.description.label',
    defaultMessage: 'Описание',
  },
  jsAdFormDescriptionPlaceholder: {
    id: 'js.ad_form.description.placeholder',
    defaultMessage:
      'Подробное описание – это обязательный параметр объявления. Если не знаете что писать, воспользуйтесь подсказкой на зеленом фоне',
  },
  jsAdFormDescriptionFootnote: {
    id: 'js.ad_form.description.footnote',
    defaultMessage:
      '\u003ch5\u003eОписывайте по пунктам:\u003c/h5\u003e\u003cul\u003e\u003cli\u003eДом и квартира (чистый подъезд, высокие потолки)\u003c/li\u003e\u003cli\u003eМебель, бытовая техника, интернет\u003c/li\u003e\u003cli\u003eИнфраструктура: все, что делает жизнь удобной (магазины, поликлиники, школы)\u003c/li\u003e\u003cli\u003eТранспорт и парковка (расстояние до метро/остановки, есть ли парковка у дома)\u003c/li\u003e\u003cli\u003eСоседи и принятый в квартире образ жизни (если сдаете комнату)\u003c/li\u003e\u003c/ul\u003e',
  },
  jsAdFormImagesLabel: {
    id: 'js.ad_form.images.label',
    defaultMessage: 'Фотографии',
  },
  jsAdFormImagesCover: {
    id: 'js.ad_form.images.cover',
    defaultMessage: 'Обложка',
  },
  jsAdFormImagesSelectText: {
    id: 'js.ad_form.images.select_text',
    defaultMessage:
      'Выберите файл на своём компьютере или\u0026nbsp;просто переместите сюда фотографии (потяните за\u0026nbsp;фото, чтобы поменять порядок)',
  },
  jsAdFormImagesFootnote: {
    id: 'js.ad_form.images.footnote',
    defaultMessage:
      '\u003ch5\u003eТребования к фотографиям:\u003c/h5\u003e\u003cul\u003e\u003cli\u003eОтсутствие на них знаков других сайтов (авито, циан, из рук в руки)\u003c/li\u003e\u003cli\u003eНаличие на них квартиры или комнаты\u003c/li\u003e\u003cli\u003eПриемлемый размер (не менее 800px)\u003c/li\u003e\u003cli\u003eНесколько четких фотографий на которых хорошо видно комнаты, кухню, ванную и тд\u003c/li\u003e\u003c/ul\u003e',
  },
  jsAdFormImagesSelectButton: {
    id: 'js.ad_form.images.select_button',
    defaultMessage: 'Выбрать фотографии',
  },
  jsAdFormImagesActionsRemove: {
    id: 'js.ad_form.images.actions.remove',
    defaultMessage: 'Удалить',
  },
  jsAdFormImagesActionsRotate: {
    id: 'js.ad_form.images.actions.rotate',
    defaultMessage: 'Повернуть',
  },
  jsAdFormSexLabel: {
    id: 'js.ad_form.sex.label',
    defaultMessage: 'Для кого',
  },
  jsAdFormSexAny: {
    id: 'js.ad_form.sex.any',
    defaultMessage: 'Не важно',
  },
  jsAdFormSexFemale: {
    id: 'js.ad_form.sex.female',
    defaultMessage: 'Только для девушек',
  },
  jsAdFormSexMale: {
    id: 'js.ad_form.sex.male',
    defaultMessage: 'Только для парней',
  },
  jsAdFormRoomsLabel: {
    id: 'js.ad_form.rooms.label',
    defaultMessage: 'Количество комнат',
  },
  jsAdFormOptionsLabel: {
    id: 'js.ad_form.options.label',
    defaultMessage: 'Параметры',
  },
  jsAdFormOptionsSpace: {
    id: 'js.ad_form.options.space',
    defaultMessage: 'Площадь',
  },
  jsAdFormOptionsSq: {
    id: 'js.ad_form.options.sq',
    defaultMessage: 'м\u003csup\u003e2\u003c/sup\u003e',
  },
  jsAdFormOptionsFloor: {
    id: 'js.ad_form.options.floor',
    defaultMessage: 'Этаж',
  },
  jsAdFormOptionsFloorsAmount: {
    id: 'js.ad_form.options.floors_amount',
    defaultMessage: 'Этажей в доме',
  },
  jsAdFormPriceLabel: {
    id: 'js.ad_form.price.label',
    defaultMessage: 'Цена',
  },
  jsAdFormExtLabel: {
    id: 'js.ad_form.ext.label',
    defaultMessage: 'Дополнительные параметры (заполнять не обязательно)',
  },
  jsAdFormExtAmenities: {
    id: 'js.ad_form.ext.amenities',
    defaultMessage: 'Удобства',
  },
  jsAdFormExtFeatures: {
    id: 'js.ad_form.ext.features',
    defaultMessage: 'Особенности',
  },
  jsAdFormPhoneLabel: {
    id: 'js.ad_form.phone.label',
    defaultMessage: 'Телефон',
  },
  jsAdFormPhoneFootnote: {
    id: 'js.ad_form.phone.footnote',
    defaultMessage:
      'Наши модераторы позвонят Вам для проверки квартиры. Пожалуйста, будьте на связи.',
  },
  jsAdFormPhoneProtectTitle: {
    id: 'js.ad_form.phone.protect_title',
    defaultMessage: 'Новинка!',
  },
  jsAdFormPhoneProtectAction: {
    id: 'js.ad_form.phone.protect_action',
    defaultMessage: 'Защитите свой мобильный',
  },
  jsAdFormPhoneProtectBuy: {
    id: 'js.ad_form.phone.protect_buy',
    defaultMessage: 'Подключить за',
  },
  jsAdFormPhoneProtectNote: {
    id: 'js.ad_form.phone.protect_note',
    defaultMessage:
      'Мы включим временный телефонный номер с переадресацией на ваш мобильный, который позволит вам скрыть свой реальный номер. Когда вы сдадите квартиру, этот временный номер отключится. Таким образом вы сможете защитить свой номер от нежелательных звонков и попадания в базу к риэлторам.',
  },
  jsAdFormPhoneProtectBenefitsFirst: {
    id: 'js.ad_form.phone.protect_benefits.first',
    defaultMessage:
      'Специальный номер с\u0026nbsp;переадресацией на\u0026nbsp;ваш телефон',
  },
  jsAdFormPhoneProtectBenefitsSecond: {
    id: 'js.ad_form.phone.protect_benefits.second',
    defaultMessage:
      'Настоящий номер скрыт, он\u0026nbsp;не\u0026nbsp;попадет в\u0026nbsp;базы к\u0026nbsp;риэлторам',
  },
  jsAdFormPhoneProtectBenefitsThird: {
    id: 'js.ad_form.phone.protect_benefits.third',
    defaultMessage: 'Фильтруем до\u0026nbsp;95% нежелательных звонков!',
  },
  jsAdFormOtherContactsLabel: {
    id: 'js.ad_form.other_contacts.label',
    defaultMessage: 'Еще контакты (не\u0026nbsp;обязательно)',
  },
  jsAdFormOtherContactsFootnote: {
    id: 'js.ad_form.other_contacts.footnote',
    defaultMessage:
      'Вы можете оставить свой емейл, ссылки на социальные сети или любой другой предпочтительный способ',
  },
  jsAdFormOtherContactsPlaceholder: {
    id: 'js.ad_form.other_contacts.placeholder',
    defaultMessage:
      'Например: пишите мне на фейсбук facebook.com/myname, после чего созвонимся и договоримся',
  },
  jsAdFormPlanLabel: {
    id: 'js.ad_form.plan.label',
    defaultMessage: 'Выберите тариф',
  },
  jsAdFormPlanFastTitle: {
    id: 'js.ad_form.plan.fast.title',
    defaultMessage: 'Сдайте быстрее',
  },
  jsAdFormPlanFastDesc: {
    id: 'js.ad_form.plan.fast.desc',
    defaultMessage: 'В\u0026nbsp;10\u0026nbsp;раз больше просмотров',
  },
  jsAdFormPlanFastText1: {
    id: 'js.ad_form.plan.fast.text1',
    defaultMessage: 'Выделено в\u0026nbsp;списке',
  },
  jsAdFormPlanFastText2: {
    id: 'js.ad_form.plan.fast.text2',
    defaultMessage: 'Всегда наверху в\u0026nbsp;результатах поиска',
  },
  jsAdFormPlanFastText3: {
    id: 'js.ad_form.plan.fast.text3',
    defaultMessage:
      '\u003cb class="color-green"\u003eБонус\u003c/b\u003e: модерация за\u0026nbsp;1\u0026nbsp;час(в\u0026nbsp;рабочие дни с\u0026nbsp;11\u0026nbsp;до\u0026nbsp;19)',
  },
  jsAdFormPlanFastAccessCommon: {
    id: 'js.ad_form.plan.fast.access_common',
    defaultMessage: 'Сразу доступно всем посетителям',
  },
  jsAdFormPlanFastAccessPaid: {
    id: 'js.ad_form.plan.fast.access_paid',
    defaultMessage: 'Видно на главной странице',
  },
  jsAdFormPlanNormalTitle: {
    id: 'js.ad_form.plan.normal.title',
    defaultMessage: 'Обычное объявление',
  },
  jsAdFormPlanNormalAccessPaid: {
    id: 'js.ad_form.plan.normal.access_paid',
    defaultMessage:
      'Доступно всем посетителям через {period} (а\u0026nbsp;сначала\u0026nbsp;— только подписчикам)',
  },
  jsAdFormPlanNormalAccessCommon: {
    id: 'js.ad_form.plan.normal.access_common',
    defaultMessage: 'Доступно в общем поиске',
  },
  jsAdFormPlanNormalChecking: {
    id: 'js.ad_form.plan.normal.checking',
    defaultMessage: 'Проверяется в\u0026nbsp;общей очереди',
  },
  jsAdFormPlanNormalFree: {
    id: 'js.ad_form.plan.normal.free',
    defaultMessage: 'Бесплатно',
  },
  jsAdFormPlanBuy: {
    id: 'js.ad_form.plan.buy',
    defaultMessage: 'Купить',
  },
  jsAdFormPlanFor: {
    id: 'js.ad_form.plan.for',
    defaultMessage: 'за',
  },
  jsAdFormActionsSave: {
    id: 'js.ad_form.actions.save',
    defaultMessage: 'Сохранить',
  },
  jsAdFormActionsPend: {
    id: 'js.ad_form.actions.pend',
    defaultMessage: 'Отправить на модерацию',
  },
  jsAdFormEditingNote: {
    id: 'js.ad_form.editing_note',
    defaultMessage:
      'При редактировании заголовка, описания, номера телефона или фотографий, объявление будет снова отправлено на модерацию',
  },
  jsOfferGalleryPhoto: {
    id: 'js.offer_gallery.photo',
    defaultMessage: 'фото',
  },
  jsOfferMapViewMap: {
    id: 'js.offer_map.view.map',
    defaultMessage: 'Карта',
  },
  jsOfferMapViewStreet: {
    id: 'js.offer_map.view.street',
    defaultMessage: 'Вид с улицы',
  },
  jsOfferFeaturingFormActionsSubmit: {
    id: 'js.offer_featuring_form.actions.submit',
    defaultMessage: 'Продолжить',
  },
  jsReactSelectNoResults: {
    id: 'js.react_select.no_results',
    defaultMessage: 'Ничего не найдено',
  },
  jsSearchAssistanceEmailFormText: {
    id: 'js.search_assistance_email_form.text',
    defaultMessage: 'Общий текст',
  },
  jsSearchAssistanceEmailFormLink: {
    id: 'js.search_assistance_email_form.link',
    defaultMessage: 'Ссылка',
  },
  jsSearchAssistanceEmailFormComment: {
    id: 'js.search_assistance_email_form.comment',
    defaultMessage: 'Комментарий',
  },
  jsSearchAssistanceEmailFormRemove: {
    id: 'js.search_assistance_email_form.remove',
    defaultMessage: 'Удалить',
  },
  jsSearchAssistanceEmailFormAdd: {
    id: 'js.search_assistance_email_form.add',
    defaultMessage: 'Добавить',
  },
  jsSearchAssistanceEmailFormSend: {
    id: 'js.search_assistance_email_form.send',
    defaultMessage: 'Отправить',
  },
  jsSearchAssistanceEmailFormPreview: {
    id: 'js.search_assistance_email_form.preview',
    defaultMessage: 'Предпросмотр',
  },
  jsSearchAssistanceEmailFormClose: {
    id: 'js.search_assistance_email_form.close',
    defaultMessage: 'Закрыть',
  },
  jsSearchAssistanceEmailFormSuccessAlert: {
    id: 'js.search_assistance_email_form.success_alert',
    defaultMessage: 'Письмо успешно отправлено',
  },
  jsSearchAssistanceFormCityId: {
    id: 'js.search_assistance_form.city_id',
    defaultMessage: 'Город',
  },
  jsSearchAssistanceFormSearchAssistantId: {
    id: 'js.search_assistance_form.search_assistant_id',
    defaultMessage: 'Помощник',
  },
  jsSearchAssistanceFormLeadId: {
    id: 'js.search_assistance_form.lead_id',
    defaultMessage: 'Заявка',
  },
  jsSearchAssistanceFormUserId: {
    id: 'js.search_assistance_form.user_id',
    defaultMessage: 'Пользователь',
  },
  jsSearchAssistanceFormName: {
    id: 'js.search_assistance_form.name',
    defaultMessage: 'Имя',
  },
  jsSearchAssistanceFormPhone: {
    id: 'js.search_assistance_form.phone',
    defaultMessage: 'Телефон',
  },
  jsSearchAssistanceFormEmail: {
    id: 'js.search_assistance_form.email',
    defaultMessage: 'Емайл',
  },
  jsSearchAssistanceFormAddress: {
    id: 'js.search_assistance_form.address',
    defaultMessage: 'Емайл',
  },
  jsSearchAssistanceFormComment: {
    id: 'js.search_assistance_form.comment',
    defaultMessage: 'Комментарий',
  },
  jsSearchAssistanceFormRoomsAmount: {
    id: 'js.search_assistance_form.rooms_amount',
    defaultMessage: 'Тип',
  },
  jsSearchAssistanceFormPriceMin: {
    id: 'js.search_assistance_form.price_min',
    defaultMessage: 'Цена мин',
  },
  jsSearchAssistanceFormPriceMax: {
    id: 'js.search_assistance_form.price_max',
    defaultMessage: 'Цена макс',
  },
  jsSearchAssistanceFormClientFeeMax: {
    id: 'js.search_assistance_form.client_fee_max',
    defaultMessage: 'Комиссия макс (%)',
  },
  jsSearchAssistanceFormSpaceMin: {
    id: 'js.search_assistance_form.space_min',
    defaultMessage: 'Площадь мин',
  },
  jsSearchAssistanceFormSpaceMax: {
    id: 'js.search_assistance_form.space_max',
    defaultMessage: 'Площадь макс',
  },
  jsSearchAssistanceFormFloorMin: {
    id: 'js.search_assistance_form.floor_min',
    defaultMessage: 'Этаж мин',
  },
  jsSearchAssistanceFormFloorMax: {
    id: 'js.search_assistance_form.floor_max',
    defaultMessage: 'Этаж макс',
  },
  jsSearchAssistanceFormSubjectIds: {
    id: 'js.search_assistance_form.subject_ids',
    defaultMessage: 'Удобства',
  },
  jsSearchAssistanceFormTagIds: {
    id: 'js.search_assistance_form.tag_ids',
    defaultMessage: 'Особенности',
  },
  jsSearchAssistanceFormAmenityIds: {
    id: 'js.search_assistance_form.amenity_ids',
    defaultMessage: 'Удобства/Особенности',
  },
  jsSearchAssistanceFormPublishingPeriod: {
    id: 'js.search_assistance_form.publishing_period',
    defaultMessage: 'Срок жизни объявления',
  },
  jsSearchAssistanceFormExpiresAt: {
    id: 'js.search_assistance_form.expires_at',
    defaultMessage: 'Дата окончания заявки',
  },
  jsSearchAssistanceFormSubwayPathMode: {
    id: 'js.search_assistance_form.subway_path_mode',
    defaultMessage: 'Способ добираться до метро',
  },
  jsSearchAssistanceFormPathDuration: {
    id: 'js.search_assistance_form.path_duration',
    defaultMessage: 'Время до метро в минутах',
  },
  jsSearchAssistanceFormRemove: {
    id: 'js.search_assistance_form.remove',
    defaultMessage: 'Удалить',
  },
  jsSearchAssistanceFormAdd: {
    id: 'js.search_assistance_form.add',
    defaultMessage: 'Добавить',
  },
  jsSearchAssistanceFormRepairs: {
    id: 'js.search_assistance_form.repairs',
    defaultMessage: 'Ремонт',
  },
  jsSearchAssistanceFormAnimals: {
    id: 'js.search_assistance_form.animals',
    defaultMessage: 'Животные',
  },
  jsSearchAssistanceFormResidents: {
    id: 'js.search_assistance_form.residents',
    defaultMessage: 'Кто будет проживать в квартире',
  },
  jsSearchAssistanceFormResidentWork: {
    id: 'js.search_assistance_form.resident_work',
    defaultMessage: 'Место работы',
  },
  jsSearchAssistanceFormUrgencyDate: {
    id: 'js.search_assistance_form.urgency_date',
    defaultMessage: 'Желаемая дата заселения',
  },
  jsSearchAssistanceFormUrgencyType: {
    id: 'js.search_assistance_form.urgency_type',
    defaultMessage: 'Как срочно нужно заселиться',
  },
  jsSearchAssistanceFormFromHomeowner: {
    id: 'js.search_assistance_form.from_homeowner',
    defaultMessage: 'Предложения от',
  },
  jsSearchAssistanceFormCustomLocation: {
    id: 'js.search_assistance_form.custom_location',
    defaultMessage: 'Расположение',
  },
  jsSearchAssistanceFormPriorities: {
    id: 'js.search_assistance_form.priorities',
    defaultMessage: 'Приоритеты',
  },
  jsSearchAssistanceFormReceiveType: {
    id: 'js.search_assistance_form.receive_type',
    defaultMessage: 'Предпочитаемый способ связи',
  },
  jsSearchAssistanceFormShowMap: {
    id: 'js.search_assistance_form.show_map',
    defaultMessage: 'Показать карту',
  },
  jsSearchAssistanceFormCloseMap: {
    id: 'js.search_assistance_form.close_map',
    defaultMessage: 'Закрыть карту',
  },
  jsSearchAssistanceFormSave: {
    id: 'js.search_assistance_form.save',
    defaultMessage: 'Сохранить',
  },
  jsBuyerPriceOpenButton: {
    id: 'js.buyer_price.open_button',
    defaultMessage: 'Предложить свою цену',
  },
  jsBuyerPriceTitle: {
    id: 'js.buyer_price.title',
    defaultMessage: 'Предложите свою цену',
  },
  jsBuyerPricePhonePlaceholder: {
    id: 'js.buyer_price.phone_placeholder',
    defaultMessage: 'Ваш номер телефона',
  },
  jsBuyerPriceValidationPhoneWrongFormat: {
    id: 'js.buyer_price.validation.phone_wrong_format',
    defaultMessage: 'Неверный формат телефона',
  },
  jsPaymentFormNewCard: {
    id: 'js.payment_form.new_card',
    defaultMessage: 'Новая карта',
  },
  jsPaymentFormLinkedCards: {
    id: 'js.payment_form.linked_cards',
    defaultMessage: 'Привязанные карты',
  },
  jsPaymentFormRemoveCard: {
    id: 'js.payment_form.remove_card',
    defaultMessage: 'удалить карту',
  },
  jsPaymentFormAddNewCard: {
    id: 'js.payment_form.add_new_card',
    defaultMessage: 'добавить новую карту',
  },
  jsPaymentFormSubmitButton: {
    id: 'js.payment_form.submit_button',
    defaultMessage: 'Оплатить',
  },
  jsPaymentFormOr: {
    id: 'js.payment_form.or',
    defaultMessage: 'или',
  },
  jsPaymentFormSecurityText: {
    id: 'js.payment_form.security_text',
    defaultMessage: 'Ваши платежные данные защищены технологией PCI DSS',
  },
  jsPaymentFormConfirmRemoveCard: {
    id: 'js.payment_form.confirm_remove_card',
    defaultMessage: 'Вы уверены, что хотите удалить карточку?',
  },
  jsPaymentFormFlashNotifierCardSuccessfullyDeleted: {
    id: 'js.payment_form.flash_notifier.card_successfully_deleted',
    defaultMessage: 'Карточка была успешно удалена',
  },
  jsPaymentCardNumber: {
    id: 'js.payment_card.number',
    defaultMessage: 'Номер карты',
  },
  jsPaymentCardExpiry: {
    id: 'js.payment_card.expiry',
    defaultMessage: 'Срок действия',
  },
  jsPaymentCardCvc: {
    id: 'js.payment_card.cvc',
    defaultMessage: 'СVC/CVV',
  },
  jsPaymentCardRememberCard: {
    id: 'js.payment_card.remember_card',
    defaultMessage:
      'Запомнить \u003cspan class="hidden-xs"\u003eкарту\u003c/span\u003e',
  },
  jsPaymentCardPlaceholdersExpiry: {
    id: 'js.payment_card.placeholders.expiry',
    defaultMessage: 'ММ/ГГ',
  },
  jsPaymentCardErrorsNumber: {
    id: 'js.payment_card.errors.number',
    defaultMessage: 'Неверный номер карты',
  },
  jsPaymentCardErrorsExpiry: {
    id: 'js.payment_card.errors.expiry',
    defaultMessage: 'Неверный срок действия',
  },
  jsPaymentCardTooltipsCvc: {
    id: 'js.payment_card.tooltips.cvc',
    defaultMessage: 'Три цифры на обратной стороне карты',
  },
  jsPaymentCardTooltipsRemember: {
    id: 'js.payment_card.tooltips.remember',
    defaultMessage: 'Сохранить карту для следующих покупок',
  },
});

export { defaultMessages, defaultLocale };
