import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip-lite';

const OfferCardVerifiedControl = React.memo(function({ isVerified }) {
  // TODO: перевод
  return (
    <span
      className={classNames('OfferCard__control', {
        'OfferCard__control--verified': isVerified,
        'OfferCard__control--not-verified control-unmoderated': !isVerified,
      })}
    >
      <Tooltip
        arrow
        arrowSize={7}
        distance={11}
        tagName="span"
        styles={{
          width: '100%',
          height: '100%',
        }}
        content={
          <span className="OfferCard__control-tooltip">
            {isVerified
              ? 'Объявление проверено модератором'
              : 'Объявление проверено автоматически'}
          </span>
        }
      >
        {''}
      </Tooltip>
    </span>
  );
});

OfferCardVerifiedControl.propTypes = {
  isVerified: PropTypes.bool,
};

OfferCardVerifiedControl.defaultProps = {
  isVerified: true,
};

export default OfferCardVerifiedControl;
