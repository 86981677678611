export const STATUS_TYPES = {
  INITIAL: 'initial',
  DECLINED: 'declined',
  ARCHIVED: 'archived',
  PUBLISHED: 'published',
};

export const AMENITY_KINDS = {
  TAG: 'tag',
  SUBJECT: 'subject',
};

export const FIELD_NAMES = {
  ADDRESS: 'address',
  CITY_ID: 'city_id',
  DESCRIPTION: 'description',
  EMAIL: 'email',
  FEATURE_PLAN_ID: 'feature_plan_id',
  FLOOR: 'floor',
  FLOORS_AMOUNT: 'floors_amount',
  IMAGE_IDS: 'image_ids',
  KIND: 'kind',
  MAIN_IMAGE_ID: 'main_image_id',
  SEX: 'sex',
  OTHER_CONTACTS: 'other_contacts',
  PHONE: 'phone',
  PRICE_VALUE: 'price_value',
  PRICE_CURRENCY: 'price_currency',
  ROOMS_AMOUNT: 'rooms_amount',
  SECURE_PHONE: 'secure_phone',
  SLUG: 'slug',
  SPACE: 'space',
  STATUS: 'status',
  AMENITY_IDS: 'amenity_ids',
  SUBWAY_IDS: 'subway_ids',
  DISTRICT_ID: 'district_id',
  TITLE: 'title',
  LAT: 'lat',
  LNG: 'lng',
  TYPE: 'type',
  LIVING_SIZE: 'living_size',
  KITCHEN_SIZE: 'kitchen_size',
  APT_NUMBER: 'apt_number',
  BUILD_YEAR: 'build_year',
  CEILING_HEIGHT: 'ceiling_height',
  BUILDING_TYPE: 'building_type',
  PROPER_TYPE: 'proper_type',
  CADASTRAL_NUMBER: 'cadastral_number',
  BALCONY_COUNT: 'balcony_count',
  LOGGIA_COUNT: 'loggia_count',
  WINDOWS_POSITION_OUTSIDE: 'windows_position_on_street',
  WINDOWS_POSITION_COURTYARD: 'windows_position_courtyard',
  COMBINED_BATHROOM_COUNT: 'combined_bathroom_count',
  SEPARATE_BATHROOM_COUNT: 'separate_bathroom_count',
  REPAIR_TYPE: 'repair_type',
  PASSENGER_ELEVATOR_COUNT: 'passenger_elevator_count',
  SERVICE_ELEVATOR_COUNT: 'service_elevator_count',
  ENTRANCE_RAMP: 'ramp',
  ENTRANCE_GARBAGE_CHUTE: 'garbage_chute',
  PARKING_TYPE: 'parking_type',
};
