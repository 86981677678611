import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import assign from 'lodash/assign';
import unset from 'lodash/unset';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import includes from 'lodash/includes';

import { defaultMessages } from '../../../../libs/i18n/default';
import { sendNewAdRequest } from '../../api/rooms';
import { KINDS, DEAL_TYPES } from '../AdCatalog/AdCatalog.constants';
import { FIELD_NAMES } from './constants';
import getElementOffset from '../../utils/getElementOffset';
import setRailsContext from '../../../common/utils/setRailsContext';
import TranslationProvider from '../../../common/components/HOC/TranslationProvider';
import AdFormActions from './AdFormActions';
import AdFormAddress from './AdFormAddress';
import AdFormAuth from './AdFormAuth';
import AdFormCity from './AdFormCity';
import AdFormContacts from './AdFormContacts';
import AdFormDescription from './AdFormDescription';
import AdFormExtraOptions from './AdFormExtraOptions';
import AdFormImages from './AdFormImages';
import AdFormKind from './AdFormKind';
import AdFormOptions from './AdFormOptions';
import AdFormPhone from './AdFormPhone';
import AdFormPlans from './AdFormPlans';
import AdFormPrice from './AdFormPrice';
import AdFormRooms from './AdFormRooms';
import AdFormSex from './AdFormSex';
import AdFormSubways from './AdFormSubways';
import AdFormDistrict from './AdFormDistrict';
import AdFormTitle from './AdFormTitle';
import AdFormType from './AdFormType';
import AdSaleFormExtraOptions from './AdSaleFormExtraOptions';

class AdForm extends React.Component {
  constructor(props) {
    super(props);

    const { ad } = this.props; // Todo: fix ad object with new fields

    this.state = {
      ad,
      errors: {},
      step: 0,
      disabledSubmit: false,
    };
  }

  componentDidMount() {
    this.calculateStep();
  }

  setAdState = (newAttrs, callback) => {
    this.setState(
      ({ ad }) => ({ ad: assign(ad, newAttrs) }),
      () => {
        if (typeof callback === 'function') {
          callback();
        }

        for (let attrname in newAttrs) {
          this.validateAttr(attrname);
        }

        this.calculateStep();
      },
    );
  };

  submitForm = () => {
    const cb = () => {
      const { ad, errors } = this.state;

      if (Object.keys(errors).length > 0) {
        this.scrollToErrors();
      } else {
        if (ad[FIELD_NAMES.TYPE] === DEAL_TYPES.RENT) {
          this.saveAdRent();
        }
        if (ad[FIELD_NAMES.TYPE] === DEAL_TYPES.DAILY_RENT) {
          this.saveAdDailyRent();
        }
        if (ad[FIELD_NAMES.TYPE] === DEAL_TYPES.SALE) {
          this.saveAdSale();
        }
      }
    };

    this.validate(cb);
  };

  validateAttr = (attrName, isPresented = false) => {
    let { errors, ad } = this.state;

    const attrValue = ad[attrName];

    if (isPresented && attrValue === null) {
      return null;
    }

    const attrErrors = validate.single(
      attrValue,
      this.validationRules()[attrName],
    );

    if (attrErrors === void 0) {
      unset(errors, attrName);
    } else {
      errors[attrName] = attrErrors;
    }

    this.setState({ errors });
  };

  calculateStep() {
    const { errors, ad } = this.state;
    const { isLogged } = this.props;

    const stepRules = [
      [],
      isLogged ? [] : [FIELD_NAMES.EMAIL],
      [FIELD_NAMES.KIND],
      [FIELD_NAMES.ADDRESS],
      [FIELD_NAMES.TITLE, FIELD_NAMES.DESCRIPTION],
    ];

    let step = 0;

    stepRules.forEach((keys, index) => {
      const stepErrorKeys = keys.filter(
        key =>
          isNil(ad[key]) ||
          isEqual(ad[key], []) ||
          includes(Object.keys(errors), key),
      );

      if (stepErrorKeys.length === 0) {
        step = index;
      } else {
        return false;
      }
    });

    this.setState({ step });
  }

  stepClass(step) {
    const { step: currentStep } = this.state;

    return 'form-step' + (currentStep < step ? '' : '');
  }

  validate(cb) {
    const { ad } = this.state;

    const errors =
      validate(ad, this.validationRules(), {
        fullMessages: false,
      }) || {};

    this.setState({ errors }, cb);
  }

  validationRules() {
    const { ad } = this.state;
    const {
      intl: { formatMessage },
      country,
    } = this.props;

    // Todo: change validation rules if it has to
    let rules = {
      [FIELD_NAMES.EMAIL]: {
        email: { message: formatMessage(defaultMessages.jsAdFormErrorsEmail) },
      },
      [FIELD_NAMES.KIND]: {
        presence: {
          message: formatMessage(defaultMessages.jsAdFormErrorsSelectRequired),
        },
      },
      [FIELD_NAMES.ADDRESS]: {
        presence: {
          message: formatMessage(defaultMessages.jsAdFormErrorsFieldRequired),
        },
      },
      [FIELD_NAMES.TITLE]: {
        presence: {
          message: formatMessage(defaultMessages.jsAdFormErrorsFieldRequired),
        },
      },
      [FIELD_NAMES.DESCRIPTION]: {
        presence: {
          message: formatMessage(defaultMessages.jsAdFormErrorsFieldRequired),
        },
      },
      [FIELD_NAMES.IMAGE_IDS]: {
        presence: {
          message: formatMessage(defaultMessages.jsAdFormErrorsImageRequired),
        },
        length: {
          minimum: 1,
          message: formatMessage(defaultMessages.jsAdFormErrorsImageRequired),
        },
      },
      [FIELD_NAMES.SPACE]: {
        presence: {
          message: formatMessage(defaultMessages.jsAdFormErrorsFieldRequired),
        },
        numericality: {
          greaterThan: 0,
          message: formatMessage(defaultMessages.jsAdFormErrorsMustBeNumeric),
        },
      },
      [FIELD_NAMES.FLOOR]: {
        presence: {
          message: formatMessage(defaultMessages.jsAdFormErrorsFieldRequired),
        },
        numericality: {
          greaterThan: 0,
          message: formatMessage(defaultMessages.jsAdFormErrorsMustBeNumeric),
        },
      },
      [FIELD_NAMES.FLOORS_AMOUNT]: {
        presence: {
          message: formatMessage(defaultMessages.jsAdFormErrorsFieldRequired),
        },
        numericality: {
          greaterThan: 0,
          message: formatMessage(defaultMessages.jsAdFormErrorsMustBeNumeric),
        },
      },
      [FIELD_NAMES.PRICE_VALUE]: {
        presence: {
          message: formatMessage(defaultMessages.jsAdFormErrorsFieldRequired),
        },
        numericality: {
          greaterThan: 0,
          message: formatMessage(defaultMessages.jsAdFormErrorsGreaterThan),
        },
      },
    };

    if (country === 'ua') {
      rules[FIELD_NAMES.PHONE] = {
        format: {
          pattern: /\+38 0[0-9]{2} [0-9]{3}-[0-9]{2}-[0-9]{2}/,
          message: formatMessage(
            defaultMessages.jsAdFormErrorsPhoneWrongFormat,
          ),
        },
      };
    } else {
      rules[FIELD_NAMES.PHONE] = {
        format: {
          pattern: /\+7 [0-9]{3} [0-9]{3}-[0-9]{2}-[0-9]{2}/,
          message: formatMessage(
            defaultMessages.jsAdFormErrorsPhoneWrongFormat,
          ),
        },
      };
    }

    if (ad[FIELD_NAMES.KIND] === KINDS.ROOM) {
      rules[FIELD_NAMES.SEX] = {
        presence: {
          message: formatMessage(defaultMessages.jsAdFormErrorsSelectRequired),
        },
      };
    }

    if (ad[FIELD_NAMES.KIND] === KINDS.APARTMENT) {
      rules[FIELD_NAMES.ROOMS_AMOUNT] = {
        presence: {
          message: formatMessage(defaultMessages.jsAdFormErrorsSelectRequired),
        },
      };
    }

    return rules;
  }

  scrollToErrors() {
    const { errors } = this.state;

    const firstElementWithError = document.getElementById(
      'ad_input_' + Object.keys(errors)[0],
    );

    if (firstElementWithError) {
      window.scrollTo({
        top: getElementOffset(firstElementWithError).top,
        behavior: 'smooth',
      });
    }
  }

  saveAdRent() {
    try {
      ga('send', 'event', 'apartment', 'submit');
    } catch (error) {}

    if (window.goog_report_conversion != null) {
      window.goog_report_conversion();
    }
    if (window.yaCounter14087668) {
      window.yaCounter14087668.reachGoal('send');
    }

    this.setState({ disabledSubmit: true }, this.sendSaveNewAd);
  }

  saveAdDailyRent() {
    try {
      ga('send', 'event', 'apartment', 'submit');
    } catch (error) {}

    if (window.goog_report_conversion != null) {
      window.goog_report_conversion();
    }
    if (window.yaCounter14087668) {
      window.yaCounter14087668.reachGoal('send');
    }

    this.setState({ disabledSubmit: true }, this.sendSaveNewAd);
  }

  saveAdSale() {
    try {
      ga('send', 'event', 'apartment', 'submit');
    } catch (error) {}

    if (window.goog_report_conversion != null) {
      window.goog_report_conversion();
    }
    if (window.yaCounter14087668) {
      window.yaCounter14087668.reachGoal('send');
    }

    this.setState({ disabledSubmit: true }, this.sendSaveNewAd);
  }

  sendSaveNewAd() {
    const { ad } = this.state;

    sendNewAdRequest(ad)
      .then(data => {
        window.location = data['redirect_to'];
      })
      .catch(errors => {
        this.setState({ disabledSubmit: false, errors }, this.scrollToErrors);
      });
  }

  render() {
    const {
      adPhoneForwardingPrice,
      cities,
      cityTitle,
      country,
      defaultCurrency,
      images,
      intl,
      isLogged,
      isEmployee,
      isUkraine,
      mapOptions,
      plans,
      subways,
      districts,
      amenities,
    } = this.props;
    const { ad, errors, disabledSubmit } = this.state;

    let stepNumber = 0;

    return (
      <div className="form form-offer">
        <div>
          <AdFormType
            intl={intl}
            type={ad[FIELD_NAMES.TYPE]}
            setAdState={this.setAdState}
            validateAttr={this.validateAttr}
            errors={errors}
          />
        </div>

        <div className="form-step">
          {!isLogged && (
            <AdFormAuth
              intl={intl}
              email={ad[FIELD_NAMES.EMAIL]}
              setAdState={this.setAdState}
              stepNumber={++stepNumber}
              validateAttr={this.validateAttr}
              errors={errors}
            />
          )}
          {isEmployee && (
            <AdFormCity
              intl={intl}
              cityId={ad[FIELD_NAMES.CITY_ID]}
              cities={cities}
              setAdState={this.setAdState}
            />
          )}
        </div>
        <div className={this.stepClass(1)}>
          <AdFormKind
            intl={intl}
            kind={ad[FIELD_NAMES.KIND]}
            stepNumber={++stepNumber}
            setAdState={this.setAdState}
            validateAttr={this.validateAttr}
            errors={errors}
          />
        </div>
        <div className={this.stepClass(2)}>
          <AdFormAddress
            intl={intl}
            address={ad[FIELD_NAMES.ADDRESS]}
            lat={ad[FIELD_NAMES.LAT]}
            lng={ad[FIELD_NAMES.LNG]}
            stepNumber={++stepNumber}
            mapOptions={mapOptions}
            setAdState={this.setAdState}
            cityTitle={cityTitle}
            validateAttr={this.validateAttr}
            errors={errors}
            isUkraine={isUkraine}
          />
          { subways.length > 0 && (
            <AdFormSubways
              intl={intl}
              subwayIds={ad[FIELD_NAMES.SUBWAY_IDS]}
              setAdState={this.setAdState}
              subways={subways}
            />
          )}
          { districts.length > 0 && (
            <AdFormDistrict
              intl={intl}
              districtId={ad[FIELD_NAMES.DISTRICT_ID]}
              setAdState={this.setAdState}
              districts={districts}
            />
          )}
        </div>

        <div className={this.stepClass(3)}>
          <AdFormTitle
            intl={intl}
            title={ad[FIELD_NAMES.TITLE]}
            setAdState={this.setAdState}
            validateAttr={this.validateAttr}
            errors={errors}
          />
          <AdFormDescription
            intl={intl}
            description={ad[FIELD_NAMES.DESCRIPTION]}
            setAdState={this.setAdState}
            validateAttr={this.validateAttr}
            errors={errors}
          />
        </div>
        <div className={this.stepClass(4)}>
          <AdFormImages
            intl={intl}
            imageIds={ad[FIELD_NAMES.IMAGE_IDS]}
            mainImageId={ad[FIELD_NAMES.MAIN_IMAGE_ID]}
            setAdState={this.setAdState}
            validateAttr={this.validateAttr}
            errors={errors}
            images={images}
          />
          {ad[FIELD_NAMES.KIND] === KINDS.ROOM && (
            <AdFormSex
              intl={intl}
              sex={ad[FIELD_NAMES.SEX]}
              setAdState={this.setAdState}
              validateAttr={this.validateAttr}
              errors={errors}
            />
          )}
          {ad[FIELD_NAMES.KIND] === KINDS.APARTMENT && (
            <AdFormRooms
              intl={intl}
              roomsAmount={ad[FIELD_NAMES.ROOMS_AMOUNT]}
              setAdState={this.setAdState}
              validateAttr={this.validateAttr}
              errors={errors}
            />
          )}
          <AdFormOptions
            intl={intl}
            space={ad[FIELD_NAMES.SPACE]}
            floor={ad[FIELD_NAMES.FLOOR]}
            floorsAmount={ad[FIELD_NAMES.FLOORS_AMOUNT]}
            setAdState={this.setAdState}
            validateAttr={this.validateAttr}
            errors={errors}
          />
          <AdFormPrice
            intl={intl}
            priceValue={ad[FIELD_NAMES.PRICE_VALUE]}
            priceCurrency={ad[FIELD_NAMES.PRICE_CURRENCY]}
            setAdState={this.setAdState}
            validateAttr={this.validateAttr}
            errors={errors}
          />
          {ad[FIELD_NAMES.TYPE] === DEAL_TYPES.RENT && (
            <>
              <AdFormExtraOptions
                intl={intl}
                amenities={amenities}
                amenityIds={ad[FIELD_NAMES.AMENITY_IDS]}
                setAdState={this.setAdState}
                validateAttr={this.validateAttr}
                errors={errors}
              />
            </>
          )}
          {ad[FIELD_NAMES.TYPE] === DEAL_TYPES.DAILY_RENT && (
            <>
              <AdFormExtraOptions
                intl={intl}
                amenities={amenities}
                amenityIds={ad[FIELD_NAMES.AMENITY_IDS]}
                setAdState={this.setAdState}
                validateAttr={this.validateAttr}
                errors={errors}
              />
            </>
          )}

          {ad[FIELD_NAMES.TYPE] === DEAL_TYPES.SALE && (
            <>
              <AdSaleFormExtraOptions
                intl={intl}
                balconyCount={ad[FIELD_NAMES.BALCONY_COUNT]}
                loggiaCount={ad[FIELD_NAMES.LOGGIA_COUNT]}
                windowsPositionOutside={
                  ad[FIELD_NAMES.WINDOWS_POSITION_OUTSIDE]
                }
                windowsPositionCourtyard={
                  ad[FIELD_NAMES.WINDOWS_POSITION_COURTYARD]
                }
                combinedВathroomCount={ad[FIELD_NAMES.COMBINED_BATHROOM_COUNT]}
                separateВathroomCount={ad[FIELD_NAMES.SEPARATE_BATHROOM_COUNT]}
                repairType={ad[FIELD_NAMES.REPAIR_TYPE]}
                passengerElevatorCount={
                  ad[FIELD_NAMES.PASSENGER_ELEVATOR_COUNT]
                }
                serviceElevatorCount={ad[FIELD_NAMES.SERVICE_ELEVATOR_COUNT]}
                entranceRamp={ad[FIELD_NAMES.ENTRANCE_RAMP]}
                entranceGarbageChute={ad[FIELD_NAMES.ENTRANCE_GARBAGE_CHUTE]}
                parkingType={ad[FIELD_NAMES.PARKING_TYPE]}
                setAdState={this.setAdState}
                validateAttr={this.validateAttr}
                errors={errors}
              />
            </>
          )}

          <AdFormPhone
            intl={intl}
            slug={ad[FIELD_NAMES.SLUG]}
            phone={ad[FIELD_NAMES.PHONE]}
            securePhone={ad[FIELD_NAMES.SECURE_PHONE]}
            setAdState={this.setAdState}
            country={country}
            validateAttr={this.validateAttr}
            errors={errors}
            defaultCurrency={defaultCurrency}
            adPhoneForwardingPrice={adPhoneForwardingPrice}
          />
          <AdFormContacts
            intl={intl}
            otherContacts={ad[FIELD_NAMES.OTHER_CONTACTS]}
            setAdState={this.setAdState}
            validateAttr={this.validateAttr}
            errors={errors}
          />
          <AdFormPlans
            intl={intl}
            plans={plans}
            currentPlanId={ad[FIELD_NAMES.FEATURE_PLAN_ID]}
            defaultCurrency={defaultCurrency}
            setAdState={this.setAdState}
            dealType={ad[FIELD_NAMES.TYPE]}
          />
        </div>
        <AdFormActions
          intl={intl}
          status={ad[FIELD_NAMES.STATUS]}
          submitForm={this.submitForm}
          disabledSubmit={disabledSubmit}
        />
      </div>
    );
  }
}

AdForm.propTypes = {
  ad: PropTypes.object,
  adPhoneForwardingPrice: PropTypes.number,
  amenities: PropTypes.array,
  cities: PropTypes.array,
  cityTitle: PropTypes.string,
  country: PropTypes.string,
  defaultCurrency: PropTypes.string,
  images: PropTypes.array,
  intl: intlShape.isRequired,
  isEmployee: PropTypes.bool,
  isLogged: PropTypes.bool,
  isUkraine: PropTypes.bool,
  mapOptions: PropTypes.object,
  plans: PropTypes.any,
  subways: PropTypes.array,
  districts: PropTypes.array,
};

export default setRailsContext(TranslationProvider(AdForm));
