import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import { DEAL_TYPES } from '../AdCatalog/AdCatalog.constants';
import TranslationProvider from '../../../common/components/HOC/TranslationProvider';
import OfferCardSlider from './OfferCardSlider';
import OfferCardClosedBaseControl from './OfferCardClosedBaseControl';
import OfferCardVerifiedControl from './OfferCardVerifiedControl';
import OfferCardPromotionControl from './OfferCardPromotionControl';
import OfferCardFavoriteControl from './OfferCardFavoriteControl';
import OfferMetro from '../OfferMetro/OfferMetro';

class OfferCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isViewed: false,
    };
  }

  componentDidMount() {
    const {
      ad: { id },
    } = this.props;

    if (this.getStorageViewedState(id)) {
      this.setState({ isViewed: true });
    }
  }

  onLinkClick = () => {
    const { isViewed } = this.state;
    const {
      ad: { id },
    } = this.props;

    if (!isViewed) {
      this.setStorageViewedState(id);
      this.setState({ isViewed: true });
    }
  };

  getPrettyRooms() {
    const {
      ad: { rooms },
      intl: { formatMessage },
    } = this.props;

    switch (rooms) {
      case 1:
        return formatMessage(
          defaultMessages.jsAdCardSpaceForRentOneRoomApartments,
        );
      case 2:
        return formatMessage(
          defaultMessages.jsAdCardSpaceForRentTwoRoomApartments,
        );
      case 3:
        return formatMessage(
          defaultMessages.jsAdCardSpaceForRentThreeRoomApartments,
        );
      case 'K': // Latin letter K
        return formatMessage(defaultMessages.jsAdCardSpaceForRentRoom);
      default:
        return formatMessage(
          defaultMessages.jsAdCardSpaceForRentMultiRoomApartments,
        );
    }
  }

  getStorageViewedState = id => {
    if (DeviceSupports.localStorage) {
      return !!localStorage.getItem(`offerId${id}_isViewed`);
    }

    return false;
  };

  setStorageViewedState = id => {
    if (DeviceSupports.localStorage) {
      localStorage.setItem(`offerId${id}_isViewed`, true);
    }
  };

  renderLabels() {
    const { isViewed } = this.state;
    const {
      showViewedLabel,
      intl: { formatMessage },
    } = this.props;

    if (showViewedLabel && isViewed) {
      return (
        <div className="OfferCard__labels">
          <div className="status status-viewed">
            {formatMessage(defaultMessages.jsAdCardStatusViewed)}
          </div>
        </div>
      );
    }

    return null;
  }

  renderPrice() {
    const {
      ad: { price },
    } = this.props;

    return (
      <div className="OfferCard__price">
        <span className="OfferCard__priceValue">{price}</span>
        {this.renderPriceCurrency()}
      </div>
    );
  }

  renderPriceCurrency() {
    const {
      intl: { formatMessage },
      ad: { price_currency: priceCurrency, deal_type: dealType },
    } = this.props;

    return (
      <span className="OfferCard__priceCurrency">
        {this.renderPriceCurrencyIcon()}
        <span className="OfferCard__priceCurrencyText">{priceCurrency}</span>
        {dealType === DEAL_TYPES.RENT &&
          `/${formatMessage(defaultMessages.jsAdCardMonthShort)}`}
      </span>
    );
  }

  renderPriceCurrencyIcon() {
    const {
      ad: { price_currency_code: priceCurrencyCode },
    } = this.props;

    if (priceCurrencyCode) {
      switch (priceCurrencyCode) {
        case 'usd':
          return '$';
        default:
          return <i className={`currency currency-${priceCurrencyCode}`} />;
      }
    }

    return null;
  }

  renderOptions() {
    const {
      ad: { space, address },
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="OfferCard__options">
        <span className="OfferCard__optionsItem">{this.getPrettyRooms()}</span>
        {space && (
          <span className="OfferCard__optionsItem">
            {space} {formatMessage(defaultMessages.jsAdCardMeterShort)}
            <sup>2</sup>
          </span>
        )}
        {address && <span className="OfferCard__optionsItem">{address}</span>}
      </div>
    );
  }

  renderTitle() {
    const {
      ad: { path, title },
    } = this.props;

    return (
      <h5 className="OfferCard__title">
        <a
          className="OfferCard__link"
          href={path}
          title={title}
          target="_blank"
          rel="noreferrer noopener"
          onClick={this.onLinkClick}
        >
          <span className="OfferCard__titleInner">{title}</span>
          <span className="OfferCard__linkClick" />
        </a>
      </h5>
    );
  }

  renderMetro() {
    const {
      ad: { subways },
    } = this.props;

    const len = subways.length;

    if (len) {
      let metroColors = [];
      let metroText = [];

      subways.forEach(({ url, text, colors }, index) => {
        metroColors = metroColors.concat(colors);

        if (url) {
          metroText.push(
            <a key={text} href={url} title={text}>
              {text}
            </a>,
          );
        } else {
          metroText.push(text);
        }

        if (len - 1 > index) {
          metroText.push(', ');
        }
      });

      metroColors = metroColors.filter(
        (color, index) => metroColors.indexOf(color) === index,
      );

      return (
        <div className="OfferCard__metro">
          <OfferMetro colors={metroColors} text={metroText} />
        </div>
      );
    }

    return null;
  }

  renderDistrict() {
    const {
      ad: { district },
    } = this.props;

    if (district) {
      return (
        <div className="OfferCard__metro">
          <a key={district.title} href={district.url} title={district.title}>
            Район {district.title}
          </a>
        </div>
      );
    }

    return null;
  }

  render() {
    const { isViewed } = this.state;
    const {
      ad: {
        id,
        favorite,
        moderated,
        closed_base,
        owned,
        title,
        featured: isFeatured,
        image_index: imageIndex,
        thumb_image_urls: imageUrls,
        blur_closed_base_images: blurClosedBaseImages,
      },
      intl,
      isCompactStatic,
      isUserLoggedIn,
      isStatic,
      isVisible,
      isHorizontal,
      isVertical,
      showViewedLabel,
      onFavorite,
      onUnfavorite,
    } = this.props;

    const isFull = !isCompactStatic;

    return (
      <div
        className={
          'OfferCard' +
          (showViewedLabel && isViewed ? ' OfferCard--viewed' : '') +
          (isHorizontal ? ' OfferCard--horiz' : '') +
          (isVertical ? ' OfferCard--vert' : '') +
          (isStatic ? ' OfferCard--static' : '') +
          (isCompactStatic ? ' OfferCard--compact-static' : '')
        }
      >
        <div className="OfferCard__image">
          <div className="OfferCard__imageInner">
            <OfferCardSlider
              intl={intl}
              activeIndex={imageIndex}
              images={imageUrls}
              title={title}
              isCompactStatic={isCompactStatic}
              isVisible={isVisible}
              blurClosedBaseImages={blurClosedBaseImages}
            />
            {isFull && !isStatic && this.renderLabels()}
            {closed_base ?
              isFull && <OfferCardClosedBaseControl isClosedBase={closed_base} />
              :
              isFull && <OfferCardVerifiedControl isVerified={moderated} />
            }
            {isFull && isFeatured && (
              <OfferCardPromotionControl
                isOfferOwned={owned}
                isUserLoggedIn={isUserLoggedIn}
                isStatic={isStatic}
              />
            )}
          </div>
        </div>
        <div className="OfferCard__content">
          {isFull && (
            <OfferCardFavoriteControl
              intl={intl}
              id={id}
              isInitialFavorited={favorite}
              isUserLoggedIn={isUserLoggedIn}
              onFavorite={onFavorite}
              onUnfavorite={onUnfavorite}
            />
          )}
          {this.renderPrice()}
          {this.renderOptions()}
          {isFull && this.renderTitle()}
          {isFull && this.renderMetro()}
          {isFull && this.renderDistrict()}
        </div>
      </div>
    );
  }
}

OfferCard.propTypes = {
  ad: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  isCompactStatic: PropTypes.bool,
  isHorizontal: PropTypes.bool,
  isStatic: PropTypes.bool,
  isUserLoggedIn: PropTypes.bool,
  isVertical: PropTypes.bool,
  isVisible: PropTypes.bool,
  showViewedLabel: PropTypes.bool,
  onFavorite: PropTypes.func,
  onUnfavorite: PropTypes.func,
  isClosedBase: PropTypes.bool,
  blurClosedBaseImages: PropTypes.bool
};

OfferCard.defaultProps = {
  isVisible: true,
};

export default TranslationProvider(OfferCard);
